@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.searchBox{
    display: flex;
    background-color: var(--bg2);
    flex-direction: row;
    border-radius: 5px;
    padding: 20px;
    justify-content: space-between;
    margin-bottom: 15px;
}

.filterbox{
    display: flex;
    font-size: var(--fontsize-large);
    color: var(--textMain);
    gap: 10px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: var(--bg4);
    border-radius: 5px;
    padding: 10px 20px;
}

.selectNetwork{
    width: 160px;
    height: 100%;
    background-color: var(--bg4);
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px;
}

.searchBoxRight{
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-end;
}

.searchBoxLeft{
    display: flex;
    flex-direction: row;
    width: 500px;
    align-items: center;
}

.resultBox{
    display: flex;
    background-color: var(--bg2);
    flex-direction: column;
    border-radius: 5px;
    padding: 20px;
    min-height: 100px;
    margin-top: 5px;
    gap: 1rem;
}

.resultOnly{
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 20px;
    background-color: var(--bg3);
    align-items: center;
    justify-content: space-between;
}

.resultCircle{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholderCircle{
    background-color: var(--bg5);
}

.resultsLogo {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.resultTitle{
    color: var(--textMain);
    font-size: var(--fontsize-big);
    font-weight: 600;
}

.resultSubtitle{
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
}

.resultTitleBox{
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.resultDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
}

.resultInfo{
    display: flex;
    flex-direction: row;
    font-size: var(--fontsize-big);
    color: var(--textMain);
    font-weight: 600;
}

.resultInfoHeader{
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: var(--textSecondary);
}

.resultsOnlyLeft{
    display: flex;
    flex-direction: row;
    width: 30%;
}

.resultsOnlyMiddle{
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: flex-start;
}

.resultsOnlyRight{
    display: flex;
    flex-direction: row;
    width: 25%;
}

.colContact{
    display: flex;
    flex-direction: column;
    color: var(--textSecondary);
    gap: 30px;
    width: 50%;
}

.resultContact{
    display: flex;
    flex-direction: row;
    color: var(--textSecondary);
    width: 100%;
}

#suchfeld-tickets{
    background-color: var(--bg7);
    color: var(--textThird);
    border: none;
    text-indent: 10px;
    border-radius: 5px;
    height: 35px;
    width: 50%;
    font-weight: 600;
}

.selectedFiltersBox{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.selectedFilters{
    border-radius: 30px;
    padding: 7px 15px 7px 15px;
    background-color: var(--bg4);
    color: var(--textMain);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}

.contactInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: var(--textMain);
    text-decoration: none;
}

.noResults {
    color: var(--textMain);
    font-size: var(--fontsize-big);
}

.deleteAllText {
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    cursor: pointer;
}