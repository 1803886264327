@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.successHeader{
    font-size: var(--fontsize-yourmum);
    font-weight: bold;
    text-align: center;
    color: var(--buttonCoMain);
}

.subheading{
    font-size: var(--fontsize-medium);
    text-align: center;
    color: var(--buttonCoMain);
    padding: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.WeiterButton{
    font-size: var(--fontsize-big);
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    height: 6vh;
    width: 13vw;
    background-color: var(--bgBlue);
    color: var(--buttonCoMain);
    margin-top: 30px;
    margin-bottom: 60px;
    cursor: pointer;
}

.gradient{
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.9), transparent);
    border-radius: 5px;
}