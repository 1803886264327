.formSuccessPage {
    height: calc(100vh - 60px);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding: 30px 25px;
    gap: 3rem;
    justify-content: space-between;
}

.formSuccessIcon{
    height: 40px;
}

.formSuccessTitle{
    font-size: var(--fontsize-humongous);
    color: var(--textMain);
    font-weight: 600;
}

.formSuccessSubtitle{
    font-size: var(--fontsize-medium);
    color: var(--light);
    width: 85%;
}

.oneMoreEvent{
    border-radius: 5px;
    padding: 20px 25px;
    background-color: var(--bgBlue);
    color: var(--buttonCoMain);
    display: flex;
    flex-direction: row;
    font-size: var(--fontsize-big);
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.toHomeButton{
    border-radius: 500px;
    padding: 15px 25px;
    background-color: var(--bg7);
    color: var(--textThird);
    display: flex;
    flex-direction: row;
    font-size: var(--fontsize-big);
    justify-content: center;
}


.backgroundStories{
    object-fit: cover;
    height: 100%;
}

.promotionBox{
    border-radius: 5px;
    background-color: var(--white);
    padding: 15px;
}

.dealTitle{
    font-size: var(--fontsize-big);
    font-weight: 600;
}

.dealNumber{
    font-size: var(--fontsize-enormous);
    font-weight: 600;
}

.dealSubtitle{
    font-size: var(--fontsize-medium);
    width: 80%;
}

.promotionHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.moreDetailsBox{
    font-size: var(--fontsize-regular);
    transition: 0.5s ease-out;
    margin-top: 5px;
}

.moreDetailsBox.hidden{
    display: none;
}

.moreDealDetails{
    font-size: var(--fontsize-small);
    margin-top: 10px;
    cursor: pointer;
}

.bookDealButton{
    background-color: var(--green);
    color: var(--buttonCoMain);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    font-size: var(--fontsize-large);
    width: 100%;
}

.promotionIntrosentence{
    color: var(--white);
    font-size: var(--fontsize-medium);
    margin-bottom: 5px;
}

.promotionMainBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
@media only screen and (min-width: 765px) {
    .formSuccessPage {
        width: 765px;
    }
}