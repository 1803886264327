@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.profil-header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 450px;
    background-color: var(--bg2);
    border-radius: 7px;
}

.profilBackgroundBox{
    border-radius: 5px;
    background-color: var(--bg2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#eventImageProfil {
    object-fit: cover;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    height: 200px;
}

.ProfilIcon{
    border-radius: 50%;
    object-fit: cover;
    width: 125px;
    height: 125px;
    background-color: var(--bg5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 30px;
    margin-top: -60px;
}

.profilButtonBox{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.profilheader{
    width: calc(100% - 60px);
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 10px 30px 20px 30px;
}

.VeranstalterTextProfil{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.VeranstalterName {
    color: var(--textMain);
    font-size: var(--fontsize-yourmum);
    font-weight: 600;
  }

#VeranstalterBezeichnung {
    color: var(--textSecondary);
    font-size: var(--fontsize-huge);
}

.BottomContentBox{
    position: unset;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.Profilbox {
    display: flex;
    flex-wrap: wrap;
    font-size: var(--fontsize-regular);
    gap: 1rem;
    position: unset;
    width: 70%;
}

.profilContactBox{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}

.dots-container {
    display: flex;
    justify-content: center;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: var(--bg4);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: var(--bg7);
  }

.VeranstalterBeschreibung {
    height: 18vh;
    background-color: var(--bg2);
    border-radius: 7px;
    position: relative;
    padding: 20px;
    width: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#backgroundBox {
    min-height: 10vh;
    width: 100%;
}

.profilDetailBox{
    width: 100%;
    gap: 1rem;
    display: flex;
}

.profilheaders {
    color: var(--textMain);
    font-size: var(--fontsize-large);
    font-weight: 600;
}

#SocialMedia {
    width: 50%;
}

#SocialMediaLink {
    color: var(--textFourth);
    text-align: start;
}

.InfoKasten {
    background-color: var(--bg3);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px;
}

#socialMediaKasten{
    background-color: var(--bg7);
}

.SocialMediaIcon {
    color: var(--textThird);
    position: unset;
    font-size: var(--fontsize-big);
}


#SocialMediaLinkText {
    position: unset;
    transform: none;
    font-weight: 600;
    color: var(--textThird);
}

.KontaktBoxFlex{
    display:flex;
    flex-direction:column;
    justify-content:center;
    gap: 10px;
}

.KontaktDatenItem {
    display: flex;
    flex-direction: row;
    font-size: var(--fontsize-medium);
    gap: 1rem;
    align-items: center;
}

.Kontakt > .KontaktDatenItem {
    margin: 30px;
    position: relative;
    left: 0.5208333333333334vw;
    line-height: 0.4970178926441352vh;
}

#KontaktDatenItemText {
    color: var(--textMain);
}

  
#KontaktDatenIcon {
    color: var(--textMain);
    font-size: var(--fontsize-big);
  }

.BoxRight{
    width: 30%;
    display: flex;
}  

.VorschauBox{
    display: flex;
    flex-direction: row;
    gap: 35px;
    overflow-y: scroll;
    flex: 1;
}

#SocialMediatitel {
    color: var(--textMain);
    font-size: var(--fontsize-small);
    position: relative;
    left: 1.5625vw;
}

.Footer {
    position: absolute;
    top: 196.81908548707753vh;
    left: 41.40625vw;
}

.floorsImage{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
}

@media (max-width: 768px) {
    .profilContactBox{
        flex-direction: column;
        gap: 10px;
    }

    #SocialMedia {
        width: auto;
    }

    .profilheader{
        align-items: flex-start;
        flex-direction: column;
        width: calc(100% - 40px);
        margin: 10px 20px 20px 20px;
        gap: 20px;
    }

    .ProfilIcon{
        width: 100px;
        height: 100px;
        margin-left: 20px;
        margin-top: -50px;
    }

    .profilButtonBox{
        width: 100%;
    }

    .Profilbox {
        width: 100%;
    }
}