@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.GuestInput {
    height: 40px;
    border-radius: 7px;
    border: none;
    text-indent: 10px;
    background-color: var(--bg3);
    color: var(--textMain);
    padding: 0;
}

.hinzufuegenBtn{
    background-color: var(--bgBlue);
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: var(--textMain);
    border-radius: 5px;
}

.InputColumn{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.table-tickets-head{
    border-bottom: 1px solid var(--textMain);
}

.table-items-row{
    display: flex;
}

.table-header-element{
    font-size: var(--fontsize-large);
    font-weight: 600;
}

.input-suchfeld{
    background-color: var(--bg7);
    color: var(--textThird);
    border: none;
    text-indent: 15px;
    border-radius: 5px;
    width: 300px;
    height: 40px;
    padding: 0px;
}

.csvBtn{
    background-color: var(--bg5);
    padding: 10px 20px;
    width: 80%;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    height: 22px;
    color: var(--textMain);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.CSV:hover{
    background-color: var(--green);
    color: var(--textMain);
}

.midContent{
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.leftSideMid{
    display: flex;
    flex-direction: row;
}

.rightSideMid{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.searchIconGuest{
    margin-left: -10%;
    color: var(--grey);
    align-items: center;
    display: flex;
}

.guestlistBoxAdd{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px;
    background-color: var(--grey);
    border-radius: 5px;
}

.mobile-guest{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 10px;
}

.guest-mobile-col{
    display: flex;
    flex-direction: column;
    overflow: scroll;
    scrollbar-width: none;
    gap: 10px;
    width: 100%;
}

.add-guest-btn{
    background-color: var(--green);
    color: var(--white);
    font-size: var(--fontsize-large);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
    .input-suchfeld{
        width: 100%;
        height: 40px;
    }

    .guestlistBoxAdd{
        flex-wrap: wrap;
    }

    .InputColumn{
        width: calc(50% - 10px);
    }

    .hinzufuegenBtn{
        width: 100%;
        margin-top: 10px;
    }

    .csvBtnBox{
        display: none;
    }

    .leftSideMid{
        width: 100%;
    }

    .searchIconGuest{
        margin-left: -8%;
    }
}