@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.StatisticHeader{
    color: var(--textSecondary);
    font-size: var(--fontsize-medium);
}

.StatisticNumber{
    color: var(--textMain);
    font-size: var(--fontsize-humongous);
    font-weight: 600;
}

.BigStatisticHeader{
    font-weight: 600;
    font-size: var(--fontsize-large);
    color: var(--textMain);
}

.StatisticItemBox{
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 20px;
    width: 49%;
    box-sizing: border-box;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.StatisticItemBoxHeader{
    display: flex;
    flex-direction: column;
}

.RowStatsBox{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.StatisticItemBoxBig{
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 20px;
    width: 33%;
    height: 37vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DatePicker{
    font-weight: 600;
    color: var(--textMain);
    text-align: center;
    white-space: nowrap;
}

.DatePickerContainer{
    color: var(--textSecondary);
    font-size: var(--fontsize-huge);
    width: 20%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: flex;
    flex-direction: row;
}

.StatsBoxFirstRowContainer{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 50%;
}


@media (max-width: 768px) {
    .DatePickerContainer{
        width: 100%;
    }

    .StatisticItemBox{
        width: 100%;
        min-height: 30vh;
    }

    .RowStatsBox{
        flex-wrap: wrap;
    }
    
    .StatsBoxFirstRowContainer{
        width: 100%;
        flex-wrap: wrap;
    }

    .StatisticItemBoxBig{
        width: 100%;
    }
}

@media screen and (max-width: 1270px) {

}