@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.postSolo{
    display: flex;
    background-color: var(--bg3);
    flex-direction: row;
    border-radius: 5px;
    padding: 20px;
    justify-content: space-between;
}

.leftSectionPost{
    display: flex;
    flex-direction: row;
    width: 80%;
}

.rightSectionPost{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 20%;
}

.picturePost{
    width: 160px;
    height: 200px;
    display: flex;
    background-color: var(--bg1);
    border-radius: 5px;
    cursor: zoom-in;
}

.middleSectionPost{
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    gap: 10px;
}

.captionPost{
    color: var(--textSecondary);
}

.postingDate{
    color: var(--textMain);
    font-size: var(--fontsize-big);
    font-weight: 600;
}

.actionsPost{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.bearbeitenPost{
    background-color: var(--bg4);
    color: var(--textMain);
    border-radius: 5px;
    width: 70%;
    align-items: center;
    flex-direction: column;
    display: flex;
    padding: 10px 20px;
    cursor: pointer;
}

.deletePost{
    background-color: var(--bg4);
    border-radius: 5px;
    padding: 10px 15px;
    color: var(--textMain);
    cursor: pointer;
}

.postType{
    color: var(--textMain);
    font-size: var(--fontsize-large);
    font-weight: 600;
}

.repeatStatus{
    color: var(--textMain);
    gap: 10px;
    font-size: var(--fontsize-medium);
    display: flex;
}

.postPlattform{
    color: var(--textMain);
    display: flex;
    flex-direction: column;
    font-size:  var(--fontsize-enormous);
    margin-top: auto;
}

.postTypeExact{
    color: var(--textSecondary);
    font-size: var(--fontsize-medium);
}