@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.back-btn-ticketing{
  border: none;
  color: var(--textMain);
  cursor: pointer;
}

.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header-btns {
  display: flex;
  width: 100%;
  gap: 30px;
}

.side-menu {
  padding-right: 30px;
  border-right: 1px solid var(--white);
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.col {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circleSidebar{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--bg5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.circle{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: var(--bg5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.col-text {
  color: var(--textMain);
  font-size: var(--fontsize-medium);
  margin-left: 20px;
  margin-right: 9px;
}

.tickets-box {
  border-radius: 5px;
  padding: 15px;
  background-color: var(--bg2);
}

.tickets-box-header {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
}

.TicketingEventBoxRowLeft{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TicketingEventBoxFirstRow{
  display: flex;
  flex-direction:column;
  height: fit-content;
  width:fit-content;
}

.eventDetailsText {
  color: var(--textMain);
  font-size: var(--fontsize-massive);
  z-index: 1;
  width: fit-content;
  height: fit-content;
}

.LastSales{
  color: var(--textSecondary);  
}

.TicketingEventBoxSecondRow{
  display: flex;
  flex-direction: column;
  height: fit-content;
  width:fit-content;
}

.TicketingEventBoxRowRight{
  height: 100%;
  display: flex;
  justify-content: center;
}

.payment-name{
  color: var(--textMain);
  font-weight: 600;
  font-size: var(--fontsize-medium);
  display: flex;
}

.payment-subtitle{
  color: var(--textSecondary);
  font-size: var(--fontsize-small);
}

.chart-box-ticketing-content{
  display: flex;
  flex-direction:column;
}

.chart-box-ticketing{
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: var(--bg4);
  border-radius: 5px;
}

.EventBoxTicketing{
  scrollbar-width: none;
  scroll-behavior: smooth;
  height: 59vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 20px;
}

/* to be moved to ticket_anlegen.css*/
.labelText{
  font-size: var(--fontsize-small);
  margin-bottom: 5px;
  color: var(--textSecondary);
}

.specialContent{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 35px;
}

.copyBox{
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  display: flex;
  width: 30px;
  height: 30px;
  flex-direction: row;
  background-color: var(--bg3);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.copyicon{
  display: flex;
  width: 15px;
  height: 15px;
  color: var(--textMain);
  align-items: center;
  justify-content: center;
}

.simpleText{
  color: var(--textSecondary);
  font-size: var(--fontsize-small);
}

.divRadio{
  margin-right: 20px;
}

.selectionRow{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.selectionColumn{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.TextInputSpecial {
  height: 25px;
  border-radius: 5px;
  border: none;
  text-indent: 5px;
  background-color: var(--bg3);
  color: var(--textMain);
  padding: 5px;
}

.TextInputSpecialSelect{
  height: 35px;
  border-radius: 5px;
  border: none;
  background-color: var(--bg3);
  color: var(--textMain);
  padding: 10px;
}

.TicketingEventBoxContent{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  text-decoration: none;
  position: absolute;
  width: calc(100% - 40px);
  height: calc(26vh - 40px);
  border-radius: 5px;
  background-color: var(--bg3);
}

.TicketingEventBox{
  height: 26vh;
  width: 100%;
  background-color: var(--bg3);
  border-radius: 5px;
  display: flex;
}

.ticketingEvent{
  height: 26vh;
  width: 100%;
}