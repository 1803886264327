@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
  
.eventEinzelnBox {
    background-color: var(--bg2);
    border-radius: 7px;
  }

.status-div{
    position: absolute;
    padding: 10px 20px;
    right: 25px;
    border-radius: 7px;
    width: fit-content;
    margin-top: 25px;
}

.below-img-text{
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.below-img-left{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tabContent {
    background-color: var(--bg2);
    min-height: 550px;
    max-height: fit-content;
    overflow: hidden;
    color: var(--textMain);
    border-radius: 0px 0px 5px 5px;
    padding: 25px;
}

.eckdatenDetails{
    display: flex;
    flex-direction: row;
    font-size: var(--fontsize-large);
    color: var(--textMain);
}

.BoxHeaderTitleDetails-Flex{
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.BoxHeaderTitleDetails{
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
}

.below-img-right{
    width: 30%;
} 

#buttonFlexEvent{
    justify-content: flex-end;
}

#editBtnText {
    font-size: var(--fontsize-regular);
    text-decoration: none;
}

.editBtn {
    cursor: pointer;
    width: 20vw;
    height: 5.467196819085487vh;
    border-radius: 5px;
    background-color: var(--bgBlue);
    text-align: center;
    color: var(--textMain);
}

.navbar-eventreihe{
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    left: 20px;
    margin-top: 20px;
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 10px 15px;
    z-index: 1;
    max-height: 40vh;
    width: 120px;
}

.navbar-eventreihe-header{
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: var(--fontsize-large);
    gap: 1rem;
    justify-content: space-between;
    color: var(--textMain);
}

.navbar-eventreihe-items{
    font-size: var(--fontsize-regular);
    color: var(--textSecondary);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 5px;
    max-height: 39vh;
    overflow-y: scroll;
    scrollbar-width: none;
}

.navbar-eventreihe-item:hover{
    color: var(--textMain);
}

.stats-box-event {
    display: flex;
    gap: 1rem;
    width: 100%
}

.Stats-Event-Einzeln{
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    width: 25%;
    padding: 15px 20px;
    background-color: var(--bg2);
}

.StatsHeader{
    display: flex;
    gap:10px;
    width: 100%;
    align-items: center;
}

.StatsIconEventEinzeln {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: var(--fontsize-regular);
    color: var(--buttonCoMain);
    display: flex;
    justify-content: center;
    align-items: center;
}

#StatsTitelEventEinzeln {
    color: var(--textSecondary);
    font-size: var(--fontsize-medium);
    width: fit-content;
}

.statsAnzahlBody{
    width: 80%;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.eventMenuTile{
    width: 32%;
    padding: 15px;
    text-align: center;
    background-color:var(--bg3);
    font-size: var(--fontsize-large);
    cursor: pointer;
    color: var(--textSecondary);
    border-radius: 5px 5px 0px 0px;
    border: 1px solid var(--bg4);
    border-width: 1px 1px 0px 1px;
    border-bottom: none;
}
  
.eventMenuTileActive{
    background-color:var(--bg2);
    color: var(--textMain);
    font-weight: 600;
    border-width: 1px 1px 0px 1px;
    height: 60%
}

.detailsheader{
    font-size: var(--fontsize-massive);
    color: var(--textMain);
    font-weight: 600;
}

.detailssubheader{
    font-size: var(--fontsize-medium);
    color: var(--textSecondary);
}

.eckdatenText{
    font-size: var(--fontsize-gigantic);
    font-weight: 600;
    color: var(--textMain);
}

.FunctionsBox {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.FunctionsOberbox {
    margin-top: 20px;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.FunctionsHeader{
    font-size: var(--fontsize-big);
    color: var(--textMain);
}

.FunctionsSubheader{
    font-size: var(--fontsize-regular);
    color: var(--textSecondary);
}

.ticketoverview-btn{
    text-align: center;
    background-color: var(--bgBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    border-radius: 10px;
    color: var(--buttonCoMain);
    font-size: var(--fontsize-regular);
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
}

#BoxHeaderTitle {
    color: var(--textMain);
    font-size: var(--fontsize-humongous);
    position: unset;
    margin-bottom: 20px;
    font-weight: 600;
    width: fit-content;
  }

.dropdown-button-einzel{
    display: flex;
    width: 50px;
    z-index: 1;
    height: 50px;
}

.ButtonsBoxEinzel{
    display: flex;
    position: unset;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    margin-left:auto;
    gap: 10px;
    justify-content: flex-end;
}

.detailsbox{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(50% - 10px);
}

.ticketingTabBox{
    display: flex;
    flex-direction: column;
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 20px;
}

.borderRadius-5px{
    border-radius: 5px;
}

.progressBarTicketingTab{
    width: 40%;
    border-radius: 5px;
    border-color: none;
    background-color: var(--bg2);
    height: 30px;
}

.textProgressBar{
    position: relative;
    top: -75%;
    left: 50%;
}

#tab-wrapper {
    height: 100%;
}

.statisticsTabBox{
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    height: 20vw;
    align-items: center;
    padding: 20px;
    background-color: var(--bg3);
    width: 29%;
}

.statisticsBoxTopRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#BoxHeaderTitleDetails {
    color: var(--blue);
    font-size: var(--fontsize-medium);
    position: unset;
}

.flex-event-einzeln-footer{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}

.EventStatisticsHeader{
    font-weight: 600;
    color: var(--textMain);
    font-size: var(--fontsize-huge);
}

.AbrechnungBox{
    background-color: var(--bg4);
    color: var(--textMain);
    border-radius: 5px;
    padding: 10px 15px;
    font-size: var(--fontsize-medium);
    gap: 10px;
    align-items: center;
    display: flex;
}

.MarketingBox{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overflow-y: scroll;
}

.PostVorschauBox{
    background-color: var(--bg3);
    width: 30%;
    border-radius: 5px;
    height: 100%;
}

.PostDateText{
    font-size: var(--fontsize-huge);
}

.noEvents{
    align-items: center;
    font-size: var(--fontsize-large);
    background-color: var(--bg3);
    height: 100px;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    color: var(--textSecondary);
}

#noTicketing {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: var(--fontsize-gigantic);
    font-weight: 600;
    color: var(--textMain);
    gap: 1rem;
}

.ticketCategoriesFlex{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ticketCategoriesLeft{
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    font-size: var(--fontsize-medium);
    flex-wrap: wrap;
}

.ticketCategoriesRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.newCodeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bgBlue);
    color: var(--textMain);
    font-size: var(--fontsize-medium);
    border-radius: 5px;
    cursor: pointer;
    gap: 10px;
    padding: 15px 20px;
}

.couponVal{
    color: var(--textThird);
    font-size: var(--fontsize-medium);
}

.coupon-col-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    justify-content: space-between;
  }
  
.coupon-col-item-left{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
  
.copy-btn{
    background-color: var(--bg5);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--textThird);
    font-size: var(--fontsize-medium);
    cursor: pointer;
    padding: 10px 25px;
    min-width: 15%;
}

.UploadBtnGallery{
    background-color: var(--bg7);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: var(--fontsize-large);
    border-style: none;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

.gallery-images-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 20px);
    gap: 20px;
    justify-content: center;
}

.gallery-image-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 10px;
    width: calc(20% - 40px);
    justify-content: center;
}

.previewAppEvent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(35% - 1rem);
}

.detailsTab{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    height: 100%;
}

.detailsTabColumn{
    display: flex;
    flex-direction: column;
    width: calc(65% - 1rem);
    flex-wrap: wrap;
    gap: 20px;
}

.ticketingTabBoxTotal{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 100%;
}

.ticketingTabBoxLeft{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    width: 60%;
    height: 100%;
}

.ticketingTabBoxRight{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    width: 40%;
    height: 100%;
}

.numberSales{
    font-size: var(--fontsize-huge);
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
}

#ticketingTabSales{
    height: 100%;
}

.mainBoxStatsTab{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
}

@media (max-width: 768px) {
    .status-div{
        right: 15px;
        margin-top: 15px;
    }

    .below-img-text{
        flex-direction: column;
        padding: 15px;
    }

    .below-img-right{
        width: 100%;
        margin-top: 10px;
    }

    #marketingTabEvent{
        display: none;
    }

    #galerieTabEvent{
        display: none;
    }

    .previewAppEvent{
        display: none;
    }

    .detailsTab{
        flex-direction: column;
        gap: 30px;
    }

    .detailsTabColumn{
        width: 100%;
        gap: 30px;
    }

    .tabContent {
        padding: 20px;
    }

    .Stats-Event-Einzeln{
        width: calc(50% - 46px);
    }

    .ticketingTabBoxTotal{
        flex-direction: column;
    }
    
    .ticketingTabBoxLeft{
        width: 100%;
    }

    .ticketingTabBoxRight{
        width: 100%;
    }

    .ticketoverview-btn{
        border-radius: 50%;
        padding: 15px;
        width: 15px;
        height: 15px;
    }

    .eckdatenText{
        font-size: var(--fontsize-massive);
    }

    .detailsbox{
        width: 100%;
    }

    .statisticsTabBox{
        height: 60vw;
        width: 90%;
    }

    .stats-box-event {
        flex-wrap: wrap;
    }
}