.paysheet-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.paysheet-payment-element {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
}

.paysheet-submit {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--bgBlue);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-weight: 600;
    color: white;
}

.paysheet-submit:disabled {
    background-color: var(--bg4);
}

.paysheet-conditions{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    display: flex;
    justify-content: center;
}

.paysheet-paymentterms{
    text-align: center;
    padding: 10px;
    color: var(--textSecondary);
}