@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");



#bild{
    position: absolute;
    text-align: center;
    left: 49vw;
    top: 20vh;
    width: 4.166666666666667vw;
    height: 8.2389289392379vh;
    transform: translate(-50%,-50%);
}

#login{
    font-size: var(--fontsize-yourmum);
    font-style: normal;
    font-weight: bold;
    color: var(--textMain);
    text-align: center;

}

.submitBtn{
    width:16vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--fontsize-regular);
    color: var(--textMain);
    height: 3.6649214659685865vh;
    background-color: var(--bgBlue);
    border-radius: 7px;
}


.mid-inputs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 350px;
}

.mid{
    display: flex;
    flex-direction: column;
    gap:4rem;
    align-items: center;
}


.break{
    border: 0;
    height:7px;    
    visibility: hidden;
}

#detailsLogin{
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
}


#password{
    background-color: var(--bg4);
    color: var(--textSecondary);
    height: 3.6649214659685865vh;
    position: absolute;
    top: 52.87958115183246vh;
    background-color: var(--bg4);
    text-indent: 0.52vw;
    width: 15.625vw;
    height: 3.6649214659685865vh;
    font-size: var(--fontsize-regular);
    left: 50%;
    transform: translate(-50%,-50%);
    right: 0%;
    border-radius: 5px;
    border: none;

}
#passwIcon{
    color: var(--textMain);
    position: absolute;
    left: 55.8vw;
    top:  51.6vh;
    font-size: var(--fontsize-large);
    cursor: pointer;
}

.error-message{
    color: var(--red);
    z-index: 1;
    font-size: var(--fontsize-medium);
    visibility: hidden;
    text-align: center;
}

#passwVergessen{
    font-size: var(--fontsize-regular);
    color: var(--textSecondary);
    cursor: pointer;
}

#loginBtn{
    cursor: pointer;
    border: none;
    background-color: var(--bgBlue);
    text-align: center;
    height: 4.5vh;
    font-weight: 600;
    width: 100%;
    border-radius: 5px;
    color: var(--textMain);
    font-size: var(--fontsize-regular);
}

.footerClass{
    color: var(--textSecondary);
    position: absolute;
    margin-top: 32%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: var(--fontsize-regular);
}

#regislink{
    text-decoration: none;
    color: var(--blue);
}

.paswordInputBox{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
}

@media only screen and (max-width:1210px) {
    #detailsLogin{
        top: 39vh;
    }

    #password{
        top: 51.5vh;
    }

    #passwIcon{
        top: 50.2vh;
    }
}