
.InputBoxTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.middleSectionWebsite{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.websiteColorBox{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.websiteColorBoxSingle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.websiteColorTitle{
    color: var(--textMain);
}

.domainPreview{
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
    margin-top: 10px;
}

.domainInUseAlert{
    color: var(--red);
    font-size: var(--fontsize-small);
    margin-top: 10px;
}