@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

body{
    background-color: var(--bg2);
}

.inputs{
    display: inline-flex;
    flex-wrap: wrap;
    width: 31.25vw;
    height: 157.06806282722513vh;
    border-radius: 8px;
}

.Adresse{
    height: fit-content ;
    position: relative;
    top: 0rem;
}

.box2 > #item {
    margin: 25px;
}

.erstellen{
    color: var(--textMain);
    font-weight: 600;
    font-size: var(--fontsize-yourmum);
}

.break{
    border: none;
    border: 0;
    size: 7px;
    visibility: hidden;
}

.btn {
    height: 35px;
    width: 35px;
    background-color: var(--bg4);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fontsize-regular);
}

.row{
    display: flex;
    flex-direction: row;
}

.colBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.col1{
    display: flex;
    gap: 10px;
    width: 100%;
}

.titelbild{
    min-height: 200px;
    position: relative;
    top: 0vh;
}

#delete-icon-logo{
    position: unset;
    top:  1.4136125654450262vh;
}

.LogoBorder{
    width: 21vh;
    height: 21vh;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name{
    height: 13.717277486910994vh;
    background: var(--darkgrey);
    border-radius: 10px;
    position: relative;
    top: 0em;
}

.inp{
    height:  3.6649214659685865vh;
    width: 10.9375vw;
    background-color: var(--bg3);
    color: var(--textSecondary);
    border-color: var(--darkgrey);
    border-radius: 5px;
    position: absolute;
    border-style: none;
    text-indent: 10px;
    left: 3.0208333333333335vw;
    font-size: var(--fontsize-small);
    top: 9.272251308900524vh;
}

#logoImg{
    border-radius: 50%;
    position: relative;
    width: 100%;
    height: 100%;
}

#region{
    width: 10.9375vw;
    height: 3.6649214659685865vh;
    border-radius: 5px;
    color: var(--textSecondary);
    background-color: var(--bg3);
    position: absolute;
    left: 15.625vw;
    font-size: var(--fontsize-regular);
    top:9.633507853403142vh;
    text-indent: 2px;
    border-style: none;
}

.footer{
    margin: 20px 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Beschreibung{
    height: 18.848167539267017vh;
    background-color: var(--bg3);
    border-radius: 10px;
    position: relative;
    top: 0em;
    left: 0px;
}

#beschreibung-inp{
    width: 24.479166666666668vw;
    height: 8.37696335078534vh;
    text-align: start;
    resize: none;
}

#checkBeschreibung{
    visibility: hidden;
    color: var(--green);
    position: absolute;
    left:  26.041666666666668vw;
    top:  10.298661174047373vh;
}

#maxLength{
    display: none;
    left: 26.5625vw;
    top: 4.18848167539267vh;
    text-align: center;
}

#erstellenBtnProfilBearbeiten{
    width: 40%;
    height: 5vh;
    background-color: var(--bgBlue);
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: var(--textMain);
    border-radius: 7px;
}

.Vorschau{
    width:  27.083333333333332vw;
    height: 161.79196704428423vh;
    position: absolute;
    left: 63.020833333333336vw;
    top: 37.8vh;
    background-color: var(--bg3);
    border-radius: 10px;
}

br{
    height: 5px;
}

.erstellenBtn{
    font-weight: 600;
    color: var(--textMain);
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 15px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#last-text{
    font-size: var(--fontsize-big);
    color: var(--textMain);
    width: 70%;
}

#output_image_erstellen{
    visibility: hidden;
}

#output_image_Vorschau{
    position: absolute;
    border: none;
    visibility: hidden;
    border-radius: 5px;
    left: 60px;
    top: 170px;
}

#Vorschau_Name{
    max-lines: 2;
    color: var(--textMain);
    position: absolute;
    top: 120px;
    left: 890px;
    z-index: 1;
    font-size: var(--fontsize-big);
}

.NameVorschauBox{
    width: 30px;
    height: 40px;
    text-align: center;
}

#checkLand{
    visibility: hidden;
    color: var(--green);
    position: relative;
    left: 11.979166666666666vw;
    top: 10.092687950566427vh;
}

#checkRegion{
    visibility: hidden;
    color: var(--green);
    position: absolute;
    left: 24.583333333333332vw;
    top: 10.298661174047373vh;
}

#checkWebsite{
    color: var(--green);
    position: relative;
    left: 12.291666666666666vw;
    top: 10.298661174047373vh;
}

#checkEmail{
    color: var(--green);
    position: relative;
    left:25.677083333333332vw;
    top:10.298661174047373vh;
    z-index: 1;
}

#checkTele{
      color: var(--green);
      position: relative;
      left: 11.40625vw;
      top: 17.30175077239959vh;
      z-index: 1;
  }


  #checkStra{
    color: var(--green);
    position: relative;
    left: 10.572916666666666vw;
    top: 26.261585993820802vh;
    z-index: 1;
  }

  #checkPlz{
    color: var(--green);
    position: relative;
    left: 430px;
    top: 255px;
    z-index: 1;
  }

  #checkOrt{
    color: var(--green);
    position: relative;
    left: 172px;
    top: 320px;
    z-index: 1;
  }

#checkLandKontakt{
    color: var(--green);
    position: relative;
    left: 397px;
    top: 320px;
    z-index: 1;
}

.TextInputBeschreibung{
    height: 150px;
    border-radius: 7px;
    border: none;
    background-color: var(--bg3);
    color: var(--textMain);
    padding: 10px 15px;
    overflow-wrap: break-word;
    resize: none;
  }

.multiEntryBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addElementMulti{
    padding: 15px;
    background-color: var(--bg3);
    border-radius: 5px;
    gap: 10px;
    color: var(--textMain);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.entryBox{
    background-color: var(--bg3);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.InputErstellen{
    background-color: var(--bg4);
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: var(--textMain);
}

.hoursBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hoursBoxSingle{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.hoursBoxSelect{
    height: 20px;
    width: 20px;
    border-radius: 500px;
    border: 1px solid var(--textSecondary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.hoursBoxSelectMini{
    height: 12px;
    width: 12px;
    border-radius: 500px;
}

.hoursBoxDay{
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
}

.hoursBoxTime{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.closed{
    border-radius: 5px;
    background-color: var(--bg4);
    width: 100%;
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--textThird);
}

.timePicker{
    background-color: var(--bg4);
    border: none;
    border-radius: 5px;
    color: var(--textMain);
    display: flex;
    padding: 10px;
    width: 80%;
}

.hoursBoxLeft{
    display: flex;
    align-items: center;
    width: 35%;
    gap: 10px;
    cursor: pointer;
}

.hoursBoxRight{
    display: flex;
    align-items: center;
    width: 65%;
    gap: 5px;
    justify-content: space-between;
}

.createProfilPage{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 90%;
}

.createProfilHeader{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}


@media only screen and (max-width: 768px) {
    .createProfilPage{
        width: 100%;
    }

    .erstellen{
        font-size: var(--fontsize-humongous);
    }
}
