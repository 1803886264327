@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

#proBenefits{
    overflow-y: hidden;
}

.mainBox{
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 30px;
    margin-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Title{
    color: var(--textMain);
    font-size: var(--fontsize-yourmum);
    font-weight: 800;
    text-align: center;
}

.Subtitle{
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
    width: 50%;
    text-align: center;
}

.TitleArea{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.previewArea{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.previewStep{
    display: flex;
    flex-direction: column;
    width: 33%;
    color: var(--textMain);
    gap: 20px;
}

.previewGraphic{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.previewText {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.previewTitle{
    font-weight: 600;
    font-size: var(--fontsize-massive);
    margin-bottom: 10px;
}

.previewSubtitle{
    color: var(--textSecondary);
    text-align: center;
    width: 80%;
}

.graphic{
    width: 80%;
}

.trialButton{
    padding: 15px 25px;
    background-color: var(--bg7);
    color: var(--textThird);
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: var(--fontsize-large);
    cursor: pointer;
    font-weight: 600;
}

.buttonArea{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.additionalInfo {
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    margin-top: 10px;
}