@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.benefitHeader{
    color: var(--textMain);
    font-size: var(--fontsize-humongous);
    font-weight: 800;
}

.benefitSubheading{
    color: var(--textSecondary);
    font-size: var(--fontsize-medium);
}

.HeaderRowBenefits{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.HeaderText{
    display: flex;
    flex-direction: column;
}

.exitCross{
    color: var(--textMain);
    font-size: var(--fontsize-humongous);
}

.benefitSquare{
    background-color: var(--bg4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    padding: 30px;
    font-size: var(--fontsize-yourmum);
}

.benefitSquareSmall{
    background-color: var(--bg4);
    color: var(--textMain);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    padding: 15px;
    font-size: var(--fontsize-big);
}

.benefitTitle{
    color: var(--textMain);
    font-size: var(--fontsize-gigantic);
    font-weight: 600;
}

.benefitDescription{
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
}

.benefitElement{
    display: flex;
    flex-direction: row;
    width: 22vw;
}

.benefitElementSmall{
    display: flex;
    flex-direction: row;
    width: 16vw;
}

.benefitText{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contentRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.benefitTitleSmall{
    color: var(--textMain);
    font-size: var(--fontsize-large);
    font-weight: 600;
}

.benefitDescriptionSmall{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
}

.mainContentBox{
    gap: 6rem;
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
}

.bigSix{
    gap: 5rem;
    display: flex;
    flex-direction: column;
}

.BottomRow{
    color: var(--textMain);
    display: flex;
    flex-direction: row;
    justify-content: center;
}