@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.event-box {
    width: 100%;
    height: 100%;
    background-color: var(--bg3);
    border-radius: 7px;
    position: relative;
    overflow-x: clip;
}

.dropdown-button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    width: 35px;
    height: 35px;
}

.event-box .link {
    color: var(--textMain);
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.event-box .link .banner {
    border-radius:7px 7px 0px 0px;
    width: 100%;
    height: 90px;
    object-fit: cover;
    background-color: var(--bg4);
}

.event-box .link .bottom {
    display: flex;
    padding: 15px;
    flex-direction: column;
    flex-shrink: 0;
    gap: 15px;
}

.event-box .link .bottom .left {
    font-size: var(--fontsize-huge);
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-box .title{
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--fontsize-massive);
}

.event-box .link .bottom .left .date {
    color: var(--blue);
    font-size: var(--fontsize-regular);
}

.event-box .link .bottom .left .location {
    display: flex;
    gap: 10px;
    margin-top: 5px;
    line-height: 100%;
    font-size: var(--fontsize-regular);
}

.ticketbestand-small{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    margin-bottom: 5px;
    text-align: left;
}

.ticketbestandbox{
    width: 150px;
}

.dropdown-box{
    background-color: var(--bg7);
    color: var(--textThird);
}


@media (max-width: 768px) {
    .event-box .link .banner {
        height: 125px;
    }
}
