.nav-search {
    width: 80%;
    margin: 0 auto;
    height: 100%;
}

.nav-search-results {
    background-color: var(--light);
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
}

.nav-search-box{
    background-color: var(--light);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: 15px;
}

.nav-search-transparent{
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    outline: none;
    flex-grow: 1;
}

.nav-search-name{
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--fontsize-large);
    font-weight: 600;
    color: var(--black);
    overflow: hidden;
}

.nav-search-result{
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
}

.nav-search-result-image{
    height: 80px;
    width: 150px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 5px;
}

@media only screen and (max-width: 768px) {
    .nav-search {
        width: calc(100% - 30px);
        margin: unset;
        height: 100%;
    }

    .nav-search-result-image{
        height: 60px;
        width: 100px;
        flex-shrink: 0;
        object-fit: cover;
        border-radius: 5px;
    }

    .nav-search-result{
        gap: 15px;
    }
}