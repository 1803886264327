.loaderDivFetch{
   display: none;
}

.loaderDiv{
  visibility: hidden;
}

.loaderDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 5;
  gap: 2rem;
}

.loaderDivNew{
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  gap: 2rem;
  border-radius: 7px;
  opacity: .9;
}

.loader {
    width: 100px;
    height: 100px;
    color: var(--textMain);
  }
  /* loader.css */
.loader .cls-1 {
    stroke: var(--textMain); 
    fill: none;    /* Keeping the fill transparent */
  }
.loaderEvent {
    perspective: 1000px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    color: var(--textMain);
  }


  .loaderOffline{
    perspective: 1000px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    color: var(--textMain);
  }

  .dot-loader {
    visibility: hidden; /* Hide the dots */
    transition: visibility .6s; /* Optional: smooth transition when changing visibility */
}

.dot-loader.visible {
    visibility: visible; /* Show the dots when they have the 'visible' class */
}
