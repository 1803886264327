@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.context-menu {
    height: 100%;
    width: 100%;
}

.context-menu .button {
    height: 100%;
    width: 100%;
    background-color: var(--bg3);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--textMain);
}

.context-menu .menu {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    width: fit-content;
    padding: 10px 15px;
    background-color: var(--bg3);
    color: var(--textMain);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 1px 0px 5px var(--grey);
}

.context-menu .menu .option {
    display: flex;
    gap: 10px;
    white-space: nowrap;
    cursor: pointer;
    transition: transform 0.5s ease;
}

.context-menu .menu .option:hover {
    transform: translateX(5px);
}