@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.marketingBox{
    display: flex;
    background-color: var(--bg2);
    flex-direction: column;
    border-radius: 5px;
    padding: 25px;
    min-height: 100px;
    height: 65vh;
}

.postBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    scrollbar-width: none;
    scroll-behavior: smooth;
    height: 59vh;
    overflow-y: scroll;
}

.ModalPostHeaderBox{
    display: flex;
    flex-direction: column;
}

.VorschauPost{
    width: 500px;
    height: auto;
    background-color: var(--bg1);
    border-radius: 5px;
}

.PlaceholderInstaBox{
    display: flex;
    background-color: var(--bg5);
    flex-direction: column;
    flex: 1;
    min-height: 0;
}

.placeholderText {
    text-align: center;
    font-size: var(--fontsize-large);
    padding: 0 50px;
}

/* Instagram Box */

.InstagramBoxTopBar{
    height: 30px;
    background-color: var(--buttonCoMain);
    padding: 10px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid var(--lightgrey);
}

.InstagramTopContentLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.InstagramTopContentIcon{
    border-radius: 15px;
    background-color: var(--bg1);
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.InstagramTopContentName{
    color: black;
    font-size: var(--fontsize-large);
}

.InstagramContentIcons{
    display: flex;
    width: 30px;
    height: 30px;
}

.InstagramTopContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.InstagramBoxGrafik{
    width: fit-content;
    flex: 1;
    min-height: 0;
    background-color: var(--bg2);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.InstagramBoxBottomBar{
    padding: 10px;
    background-color: var(--buttonCoMain);
    border-top: 1px solid var(--lightgrey);
}

.InstagramBoxBottomContentLeft{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.InstagramBoxBottomContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.postCancelButton{
    background-color: var(--bg4);
    color: var(--textMain);
    width: 200px;
    height: 55px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 30px;
}

.TitleText{
    color: var(--textMain);
    font-size: var(--fontsize-big);
    font-weight: 600;
    margin-bottom: 10px;
}

.PostCreationLeft{
    display: flex;
    flex-direction: column;
}

.PostCreationRight{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.PostCreationAll{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 150px;
    flex: 1;
    min-height: 0;
}

.PostAngabenBoxMain{
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
}

.PostAngabenBox{
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 1rem;
    border-radius: 5px;
    background-color: var(--bg3);
    margin-right: 15px;
}

.Angabe{
    color: var(--textSecondary);
    font-size: var(--fontsize-big);
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.selectMarketing{
    width: 70%;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    background-color: var(--bg4);
    color: var(--textMain);
    margin: 0;
    padding: 0;
}

.selectMarketingMini{
    width: 50%;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    background-color: var(--bg4);
    color: var(--textMain);
    margin: 0;
    padding: 0;
}

.DesignAdditionalBox {
    display: flex;
    width: 70%;
    flex-direction: column;
    margin-left: auto;
    gap: 1rem;
}

#postCaption{
    color: var(--textMain);
    border-radius: 5px;
    border: none;
    background-color: var(--bg4);
    width: calc(70% - 20px);
    min-height: 100px;
    resize: none;
    padding: 5px 10px;
}

#postCaption:focus{
    outline: none;
}

.datePicker{
    width: 180px;
    background-color: var(--bg4);
    height: 35px;
    border: none;
    border-radius: 5px;
    color: var(--textMain);
    text-indent: 10px;
    padding: 0;
}

.datePicker:focus-visible{
    outline: none;
}

.plattformBox{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 70%;
}

.plattformBoxSmall{
    display: flex;
    flex-direction: column;
    background-color: var(--bg4);
    border-radius: 5px;
    padding: 7px 15px;
}

.plattformBoxSmallTop{
    background-color: var(--bg4);
    color: var(--textMain);
    display: flex;
    align-items: flex-start;
}

#weekPostImg{visibility: hidden;}
#monthPostImg{visibility: hidden;}

.leftSideBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--textMain);
    font-size: var(--fontsize-big);
    margin-left: 10px;
    width: 80%;
}

.typeBox{
    display: flex;
    flex-direction: column;
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
    width: 100%;
}

.postModificationBox{
    display: flex;
    flex-direction: column;
    color: var(--textMain);
    gap: 5px;
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 5px;
}

.specificPost{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.previewPostTypesBox{
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}

.uploadBtnMarketing{
    background-color: var(--bg4);
    color: var(--textMain);
    font-weight: 600;
    width: 50%;
    height: 35px;
    border-radius: 5px;
    border-style: none;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

.selectedimagePost{
    background-color: var(--bg4);
    color: var(--textSecondary);
    width: 70%;
    border-radius: 5px;
    height: 35px;
    text-indent: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.deleteBox{
    height: 25px;
    width: 25px;
    display: flex;
    background-color: var(--bg3);
    border-radius: 5px;
    margin-right: 5px;
    font-size: var(--fontsize-regular);
    align-items: center;
    justify-content: center;
    text-indent: 0px;
}

.moreDesign {
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    color: var(--textMain);
    cursor: pointer;
}

.colorBox {
    cursor: pointer;
    background-color: var(--bg4);
    border-radius: 5px;
    width: 50%;
    height: 35px;
    border-style: none;
}

.loadingImgBox {
    background-color: var(--bg4);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fontsize-small);
    color: var(--textMain);
    background-color: var(--bg4);
    height: 35px;
    border-style: none;
    z-index: 1;
    cursor: pointer;
}

.placeholderImgBox {
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: var(--fontsize-regular);
    color: var(--textMain);
    background-color: var(--bg4);
    height: 35px;
    border-style: none;
    z-index: 1;
    cursor: pointer;
    width: 50%;
    min-width: 0;
    justify-content: space-between;
}

.imgName {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-indent: 10px;
}

.deleteBgImgBox{
    margin-right: 5px;
    display: flex;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    flex-direction: row;
    background-color: var(--bg3);
    color: var(--textMain);
    font-size: var(--fontsize-regular);
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }

.enlargedPic {
    display: flex;
    height: 50vh;
    width: fit-content;
    object-fit: cover;
    border-radius: 5px;
}


@media screen  and (max-width:1250px){
    .PostAngabenBox{
        min-width: 550px;
    }
    .datePicker{
        width: 160px;
    }
}