.artistPlaceholderImage{
    height: 25px;
    width: 25px;
    border-radius: 500px;
    background-color: var(--bg5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--buttonCoMain);
    font-size: var(--fontsize-small);
}

.suggestionRow{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.suggestionRowLeft{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 90%;
}

.artistSuggestionImage{
    height: 25px;
    width: 25px;
    border-radius: 50px;
    object-fit: cover;
}

.playCircleArtist{
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background-color: var(--bg5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--buttonCoMain);
}

.suggestionRowVenue{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}