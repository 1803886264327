@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

  #root::-webkit-scrollbar{
      width: 10px;
  }

  body{
    user-select: none;
    overflow-x: hidden;
    background-image: none;
    background-color: var(--bg3);
  }
  
  .nextEventGridBox{
    display: flex;
    height: fit-content;
    background-color: var(--bg2);
    flex-direction: column;
    border-radius: 7px;
    padding: 20px;
    min-height: 34.77%;
    gap: 15px;
  }

  @media screen and (max-width:808px) {
    .nextEventGridBox{
      min-height: 22%;
    }
  }
  .DashBottomBox{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex: 1;
    min-height: 0;
  }

  .DashBottomBoxLeft{
    width: 62%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .DashBottomBoxRight{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 37%;
    gap: 1rem;
  }
  
  .AktivitaetBoxInGrid{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70%;
    flex: 1;
    min-height: 0;
  }
  
  .AktivitaetBox{
    background-color: var(--bg2);
    width: 100%;
    border-radius: 7px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .sliderIcon{
    cursor:pointer
  }
  
  .sliderDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  
  .newsTextDiv{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
  }
  
  .ActiveDot{
    font-size: var(--fontsize-enormous);
    color: var(--textMain);
    font-weight: 600;
    line-height: 1;
  }
  
  .newsText{
    text-align: center;
    color: var(--textMain);
    font-size: var(--fontsize-big);
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .NotActiveSliderText{
    display: none;
  }
  
  .sliderDotsDiv{
    display: flex;
    flex-direction: row;
    gap: .5rem;
    color: var(--textSecondary);
    justify-content: center;
    align-items: flex-end;
    font-size: var(--fontsize-enormous);
  }
  
  .sliderDot{
    cursor: pointer;
    text-align: center;
    line-height: 1;
  }

  .placeholderNextEvent{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .NewsBoxInGrid{
    display: flex;
    justify-content: center;
    border-radius: 7px;
    width: 100%;
  }
  
  .NewsBox{
    background-color: var(--bg2);
    width: 100%;
    border-radius: 7px;
    padding: 20px;
    gap: 15px;
  }
  
  #UserIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--bg5);
    color: var(--textMain);
    text-indent: 10px;
    display: flex;
    align-items: center;
    justify-content: center ;
  }
  
  #logoutBtn {
    background-color: var(--bg4);
    color: var(--textMain);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: var(--fontsize-big);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .flexTileEintritt{
    background-color: var(--bg3);
    border-radius: 5px;
  }
  
  .flexTileTicketing{
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
  }

  .specialBoxHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .specialBoxHeaderLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .tileTextEintritt{
    margin: 15px;
    font-size: var(--fontsize-medium);
    font-weight: 600;
    color: var(--textMain);
  }

  .tileHeader{
    font-size: var(--fontsize-medium);
    font-weight: 600;
    color: var(--textMain);
  }

  .tileSubText{
    font-size: var(--fontsize-small);
    color: var(--textSecondary);
  }

  .zusatzfunktionen{
    font-size: var(--fontsize-regular);
    color: var(--textSecondary);
  }
  
  .password-inputs-col{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: fit-content;
  }
  
  #comingSoonText {
    color: var(--textMain);
    text-align: center;
    position: absolute;
    top: 20px;
    left: 320px;
  }

  .stats-box-dashboard {
    display: flex;
    gap: 20px;
  }

  @media screen and (max-width: 1234px) {
    .stats-box-dashboard {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .stats{
      width: calc(50% - 50px);
    }
  }

  @media screen and (max-width: 740px) {
    .stats{
      width: 100%;
    }
    
  }
  
  .stats {
    border-radius: 7px;
    padding: 30px 25px;
    background-color: var(--bg2);
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 3rem;
  }
  
  .stats-icon {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    color: var(--buttonCoMain);
    font-size: var(--fontsize-huge);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .stats-title {
    font-size: var(--fontsize-big);
    font-weight: 600;
    color: var(--textSecondary);
    line-height: 1;
  }

  .stats-home-box{
    display: flex;
    flex-direction: column;
  }
  
  .stats-subtitle {
    font-size: var(--fontsize-small);;
    color: var(--textSecondary);
  }

  .nextEventBox {
    background-color: var(--bg3);
    position: unset;
    border-radius: 7px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height:  100%;
  }

  #eventDetailsText {
    color: var(--textMain);
    font-size: var(--fontsize-large);
    z-index: 1;
  }
  
  .Uebersicht {
    width: 100%;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: row;
    position: unset;
    transform: unset;
    gap: 1rem;
    border-radius: 7px;
  }
  
  .TicketBox {
    background-color: var(--bg2);
    border-radius: 7px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 15px;
  }

  .statistikBox {
    width: 55%;
    background-color: var(--bg2);
    border-radius: 7px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .coupon-box-body-flex{
    height: 85%;
    border-radius: 7px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 10px;
  }

  .activity-box-body-flex{
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
    gap: 10px;
  }
  
  .activity-timestamp{
    color: var(--textMain);
    font-size: var(--fontsize-small);
  }
  
  .coupon-code-subname{
    font-size: var(--fontsize-small);
    color: var(--textSecondary);
    text-align: start;
    width: 16vw;
  }
  
  .coupon-code-name{
    font-weight: 600;
    font-size: var(--fontsize-medium);
    color: var(--textMain);
  }
  
  .couponVal{
    color: var(--textThird);
    font-size: var(--fontsize-medium);
  }

  .activityVal{
    color: var(--textFourth);
    font-size: var(--fontsize-large);
  }
  
  #instagram {
    position: absolute;
    left: 1.5625vw;
  }
  
  .EventBox {
    scrollbar-width: none;
    scroll-behavior: smooth;
    height: 63vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 1.5rem;
  }

  
  .UploadBtn {
    background-color: var(--bg7);
    border-radius: 5px;
    border-style: none;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
  }
  
  #UploadBtn {
    visibility: visible;
  }
  
  .circle-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  #circle-dashboard{
    background-color: var(--bg7);
  }

  .StatisticItemBoxMini{
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 15px;
    height: 85%;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggleThrough {
  color: var(--textMain);
  cursor: pointer;
}

.BoxMiniHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.statisticMiniPlaceholder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  color: var(--textMain);
}

.helpBox{
  background-color: var(--bg7);
  display: flex;
  border-radius: 5px;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  z-index: 1;
  position: fixed;
  margin-top: 10px;
  right: 6%;
  width: 250px;
}

.helpBoxTitle {
  font-size: var(--fontsize-big);
  font-weight: 600;
  color: var(--textThird);
}

.helpBoxSectionTitle {
  font-size: var(--fontsize-small);
  color: var(--textThird);
}

.helpSectionBox {
  font-size: var(--fontsize-regular);
  color: var(--textThird);
  background-color: var(--bg6);
  border-radius: 5px;
  padding: 10px;
  gap: 15px;
  display: flex;
  align-items: center;
}

.helpSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

#questionbox{
  font-size: var(--fontsize-big);
  color: var(--textThird);
}

.faqBox {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--textThird);
  background-color: var(--bg6);
  border-radius: 5px;
  justify-content: space-between;
  cursor: pointer;
}

.activityLeft{
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 80%;
}

.activityRight{
  display: flex;
  flex-direction: row;
  width: 20%;
  text-align: right;
  justify-content: flex-end;
}

.activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
}

.activity .coupon-code-subname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.activityText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
  
  /* Start of Responsiveness */
  
  @media screen and (max-width: 1700px) {
  
    #userIconPic{
      width: 40px;
      height: 40px;
    }
  }
  
  @media screen and (max-width:1450px) {
    .tableModalPayments{
      max-height: calc(60vh - 15%);
    }
  }
  
  @media screen and (max-width:1200px) {
    .tableModalPayments{
      max-height: calc(60vh - 20%);
    }
  }
  
  @media screen and (max-width:1115px) {
    .tableModalPayments{
      max-height: calc(60vh - 25%);
    }
  }
  
  @media screen and (max-height:730px){
    #coupon-flex{
      height:68%;
    }
  }
  
  
  @media screen and (max-width:1267px){
    .stats{
      padding: 20px;
    }
  
  }
  
  @media screen and (max-width:1215px){
    .stats{
      padding: 10px;
    }
    
  }
  
  @media screen and (max-width:1170px){
    .stats{
      padding: 10px;
    }
  
  }
  
  @media screen and (max-width:1121px) {
    .stats{
      padding: 5px;
    }
  }
  
  
  @media screen and (max-width:1460px) {
    .VorschauIFrame{
      height: 76vh;
    }
  }
  
  @media screen and (max-width:1530px) {
    .eventDraft{
      height: 19vh;
    }
  }
  
  
  @media screen and (max-height:800px) {
    .Kontakt > .KontaktDatenItem{
      margin: 20px;
    }
  
  }

  @media only screen and (max-width: 1500px) {
    .stats-icon {
      width: 50px;
      height: 50px;
      font-size: var(--fontsize-big);
    }
  }

@media only screen and (max-width: 768px) {

    .statistikBox{
      width: unset !important;
      height: 250px !important;
      order: 2;
    }

    .nextEventGridBox{
      min-height: 267px !important;
    }

    #statsMobileBox{
      height: 90% !important;
    }

    .placeholderNextEvent{
      height: 120px;
    }

    .placeholderNextEvent{
      height: 120px;
    }
}


@media screen and (max-width: 1355px) {
  .DashBottomBox{
    min-height: unset !important;
  }

  .content{
    overflow-y: scroll !important;
  }
  
  .activity-box-body-flex{
    max-height: 270px;
  }

  .Uebersicht{
    min-height: unset;
  }
}



@media screen and (max-width: 1207px) {
  .DashBottomBoxRight{
    display: none;
  }

  .NewsBoxInGrid{
    width: 30% !important;
    height: 100% !important;
  }

  .NewsBox{
    display: flex !important;
    flex-direction: column !important;
    gap: 7rem !important;
  }

  .AktivitaetBoxInGrid{
    height: 100% !important;
  }

  .DashBottomBox{
    flex-direction: column;
  }

  .DashBottomBoxLeft{
    width: unset;
  }
  .nextEventGridBox{
    width: calc(100% - 40px);
  }
}


@media screen and (max-width: 808px) {
  .Uebersicht{
    flex-direction: column;
  }

  .statistikBox{
    width: calc(100% - 40px);
    order: 2;
    min-height: 300px;
  }

  .TicketBox{
    width: calc(100% - 40px);
    order: 1;
    min-height: 300px;
  }
  
}