@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.sideNav{
    background-color: var(--bg1);
    overflow-x: hidden;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 30px);
}

#logo {
    display: flex;
    justify-content: center;
}

.box {
    display: flex;
    flex-direction: column;
    margin-top: 69px;
    gap: 10px
}

.TextNavBar{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
}

.currentPage {
    background-color: var(--bg7);
    font-size: var(--fontsize-regular);
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    color: var(--textThird);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    gap: 25px;
}

.Page {
    font-size: var(--fontsize-regular);
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    color: var(--textMain);
    display: flex;
    align-items: center;
    padding: 20px 30px;
    gap: 25px;
}

.Page:hover{
    background-color: var(--bg4);
}

.createEventButton {
    font-size: var(--fontsize-regular);
    background-color: var(--buttonBgMain);
    color: var(--buttonCoMain);
    padding: 20px 30px;
    border-radius: 5px;
    gap: 10px;  
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width: 1540px) {
    .TextNavBar{
        display: none;
        justify-content: flex-start;
        align-items: center;
    }

    #buttonTextCreate{
        display: none;
    }

    .createEventButton{
        padding: 20px;
    }

    .Page{
        padding: 20px;
    }

    .currentPage{
        padding: 20px;
    }
}

@media screen and (min-width: 768px) {
    .sideNav {
      display: flex !important;
    }

    #containerDashboard{
        width: unset !important;
    }
}

@media screen and (max-width: 768px) {
    .sideNav{
        position: fixed;
        top: 60px;
        z-index: 5;
        height: calc(100vh - 90px);
        display: none;
    }

    
    #containerDashboard{
        width: 100%;
    }

    .stats-icon{
        width: 40px !important;
        height: 40px !important;
    }

    .stats{
        padding: 10px 20px;
    }
}
