@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

#fastFertig{
    font-size: var(--fontsize-yourmum);
    font-style: normal;
    font-weight: bold;
    /* text-align: center; */
    color: var(--textMain);
}

#detailsFastFertig{
    color: var(--textSecondary);
    text-align: center;
    font-size: var(--fontsize-large);
}

#logo1{
    position: absolute;
    left: 50vw;
    right: 0vw;
    top: 20vh;
    width: 4.2vw;
    height: 8.4vh;
    transform: translate(-50%,-50%);
}

.inputSignup{
    background-color: var(--bg4);
    color: var(--textSecondary);
    text-indent: 10px;
    width: 100%;
    height: 4.5vh;
    padding: 0;
    position: relative;
    font-size: var(--fontsize-medium);
    border-radius: 5px;
    border: none;
    color: var(--textMain);
}


.InputsBox{
    height: fit-content;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
}

.InputsBox > .Input {
    margin:2vh;
}

br{
    height: 1px;
}
.break{
    border: 0;
    height:6px;    
    visibility: hidden;
}

.erstellenBtnFastFertig{
    border: none;
    font-weight: 600;
    color: var(--textMain);
    background-color: var(--blue) ;
    border-color: var(--blue);
    width: 100%;
    height:  4.5vh;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.passwordPolicy{
    line-height: 0.2;
    display:none;
    color: var(--textMain);
    width: 15.104166666666666vw;
}

.erstellenBtnFastFertigError{
    font-weight: 600;
    color: var(--textMain);
    background-color: var(--bg4) ;
    border-color: var(--lightgrey);
    width: 100%;
    height:  4.5vh;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
}
#BtnText{
    font-size: var(--fontsize-regular);
    /* position: relative;
    top: 15%;
    left: 50%;
    transform: translate(-50%,-50%); */
}

#passwIconRegis{
    font-size: var(--fontsize-regular);
    width: fit-content;
    color: var(--textMain);
    position: absolute;
    right: 5%;
    cursor: pointer;
    z-index: 1;
}

#passwIcon2{
    width: fit-content;
    color: var(--textMain);
    position: absolute;
    right: 10px;
    top: 26%;
    cursor: pointer;
}

.passwInput{
    height: 4.5vh;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}


.passwInputAgain{
    height: 3.8vh;
}


.login-link-text-register{
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
    text-align: center;
}

.linkBottomRegister{
    color: var(--blue);
    font-weight: 600;
}


.passw-checklist-register{
    /* transform: translate(-50%); */
    color: var(--textMain);
    /* margin: 300px; */
    min-width: 350px;
    margin-top: 30px;
}

.InputsFields{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

@media only screen and (max-width:1746px) {
    #BtnText{
        top: 10%;
    }
}

