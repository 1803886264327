/* GENERAL - start */

.statsPlaceholderLine {
    color: var(--textMain);
    text-shadow: 1px 1px 2px var(--textThird);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    height: 100%;
    width: 90%;
    position: absolute; 
    z-index: 3; 
}

.statsPlaceholderBox {
    display: flex;
    flex-direction: column;
    position: relative; 
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

/* GENERAL - end */



/* PIE - start */

.statsPieBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 2rem;
}

.statsPieContainer{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.statsPieLabelBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.statsPieLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statsPieLabelNumber{
    font-size: var(--fontsize-humongous);
    font-weight: 600;
}
  
.statsPieLabelDescription{
    font-size: var(--fontsize-regular);
}

/* PIE - end */

.statsLineChart {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}