.NotFoundBox{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--bg3);
}

.HomeLink{
    padding: 10px 30px;
    border-radius: 500px;
    min-width: 200px;
    color: var(--buttonCoMain);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--buttonBgMain);
    gap: 1rem;
    font-size: var(--fontsize-big);
}

.errorText404 {
    color: var(--textMain);
    font-size: var(--fontsize-humongous);
    font-weight: 600;
    text-align: center;
}

.errorTextSmall {
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
}

.errorBox404{
    display: flex;
    flex-direction: column;
    align-items: center;
}