@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.tile{
    width: 95%;
    background-color: var(--bg2);
    height: 75px;
    border-radius: 10px;
    padding: 20px;
}

.tile-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.tile-left {
    display: flex;
    flex-direction: column;
    width: 55%;
}

.add-scanner-btn{
    background-color: var(--bg2);
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 20px;
    cursor: pointer;
    padding: 15px 30px;
    font-size: var(--fontsize-big);
    color: var(--textSecondary);
}

.code-title {
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
}

.code {
    font-weight: 800;
    color: var(--textMain);
    font-size: var(--fontsize-enormous);
}

.code-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.delete-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.delete-icon {
    color: var(--grey);
    font-size: var(--fontsize-large);
    cursor: pointer;
}

.scannerBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 42.69767441860465vh;
    overflow-y: scroll;
}

.title {
    font-weight: 600;
    color: var(--textMain);
    font-size: var(--fontsize-big);
    margin-bottom: 10px;
}

.mainBoxScan {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: 100%;
}

.main-content-modal {
    width: 60%;
    height: fit-content;
}

.scanner-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.scanner-box-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}

.scanner-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.scanner-name-title {
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
    margin-bottom: 5px;
}

.headerBox {
    display: flex;
    flex-direction: column;
}

.headerNameRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.headerSubheader {
    display: flex;
    flex-direction: row;
    color: var(--textSecondary);
    margin-top: 10px;
    font-size: var(--fontsize-big);
    align-items: center;
}

.scannerInput {
    width: 95%;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    background-color: var(--bg4);
    color: var(--textMain);
    padding: 5px;
    font-size: var(--fontsize-medium);
}

.QrScanapp{
    object-fit: cover;
    border-radius: 5px;
    width: 80%;
}

.scannerQrBox{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 35%;
    background-color: var(--bg4);
    align-items: center;
    padding: 30px;
    border-radius: 5px;
}

.scannerQrTitle{
    font-size: var(--fontsize-regular);
    color: var(--textMain);
    text-align: center;
}

.scannerContent{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    overflow-y: scroll;
    scrollbar-width: none;
}

@media (max-width: 768px) {
    .scannerContent{
        flex-direction: column;
        scrollbar-width: none;
    }

    .main-content-modal {
        width: 100%;
    }

    .scannerQrBox{
        width: calc(100% - 40px);
    }

    .headerSubheader {
        font-size: var(--fontsize-large);
    }

    .tile{
        padding: 15px;
    }
}