@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

body{
    background-color: var(--bg3);
    background-size: 120%;
    background-repeat: no-repeat;
}

.back-btn-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: var(--textSecondary);
    gap: 10px;
    cursor: pointer;
    font-size: var(--fontsize-medium);
}

.SubheaderSignup{
    color: var(--textSecondary);
    text-align: center;
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: var(--fontsize-medium);
}
    
#erstellenBtnZwischen{
    background-color: var(--blue) ;
    padding: 15px 35px;
    z-index: 1;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fontsize-big);
    color: var(--textMain);
    font-weight: 600;
}

.zwischenSeiteContent{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -82px;
}

.zwischenContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorText{
    width: 65vw;
    height: 50vh;
    border-radius: 5px;
}

.errorTextTitle{
    font-size: var(--fontsize-yourmum);
    color: var(--textMain);
    font-weight: 600;
}

.errorTextDetails{
    font-size: var(--fontsize-huge);
    color: var(--textMain);
}

.ErrorBtn{
    background-color: var(--bgBlue);
    color: var(--textMain);
    font-size: var(--fontsize-massive);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    padding: 5px 25px;
}

.errorBox{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 20px;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 40px);
}

.ErrorBtnText{
    color: var(--textMain);
    text-decoration: none;
}

.ReloadText{
    color: var(--textMain);
}

.LogoSignup{
    cursor: pointer;
    height: 35px;
}