.pmb-modal {
    width: 50vw;
}

.pmb-container {
    color: var(--white);
    padding: 30px;
    overflow-y: scroll;
    height: calc(100% - 60px);
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.pmb-progress-bar-wrapper{
    background-color: var(--white);
    border-radius: 500px;
    color: black;
    text-align: center;
    width: 100%;
    height: 20px;
    position: relative;
}

.pmb-progress-bar-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pmb-progress-bar{
    background-color: var(--green);
    height: 100%;
    width: 100%;
    border-radius: 200px;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 0%;
    transition: max-width 1s;
}

.backBox{
    height: 30px;
    width: 30px;
    background-color: var(--grey);
    border-radius: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.pmb-nav{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.pmb-header{
    font-size: var(--fontsize-gigantic);
    font-weight: 600;
}

.pmb-col{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.pmb-input{
    display: flex;
    border: none;
    padding: 10px;
    background-color: var(--bg4);
    border-radius: 5px;
    color: var(--textMain);
}

.pmb-billing{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.pmb-selectionItem{
    font-size: var(--fontsize-medium);
}

.pmb-selection{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pmb-headAdvantages{
    margin-top: 10px;
    margin-bottom: 5px;
}

.pmb-advantage{
    margin-bottom: 15px;
}

.pmb-button{
    border-radius: 5px;
    background-color: var(--blue);
    color: var(--white);
    width: 100%;
    padding: 10px;
    font-size: var(--fontsize-large);
    font-weight: 600;
}

.pmb-button:disabled{
    opacity: 0.5;
}

.pmb-buttonAlternative{
    border-radius: 5px;
    background-color: var(--white);
    color: var(--black);
    width: 100%;
    padding: 10px;
    font-size: var(--fontsize-large);
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
}

.pmb-bundleImage{
    border-radius: 5px;
    width: 100%;
    height: auto;
    background-color: bisque;
}

.pmb-alternativeBox{
    background-color: var(--grey);
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
}

.pmb-alternative-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pmb-alternativePrice{
    font-size: var(--fontsize-large);
    font-weight: 600;
}

.pmb-alternativeBundleName{
    font-size: var(--fontsize-medium);
    font-weight: 600;
}

.pmb-payment-conditions{
    color: var(--lightgrey);
    font-size: var(--fontsize-small);
    text-align: center;
    margin-top: 5px;
}

.pmb-payment{
    margin-top: 10px;
}

.pmb-payment-button{
    border-radius: 5px;
    width: 100%;
    background-color: var(--white);
    padding: 10px;
    font-weight: 600;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .pmb-modal {
        width: 90vw;
    }

    .pmb-container {
        padding: 15px;
        height: calc(100% - 30px);
    }
}