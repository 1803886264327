@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.nextEventBoxDetailsLeft{
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:1.5rem;
    margin-top:5px;
  }
  
.nextEventBoxDetailsRight{
    display: flex;
    align-items: center;
    gap:1.5rem;
    height: fit-content;
    margin-left: auto;
    margin-right: 20px;
}

.rowItemNextEvent{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
}

.inputs-mid-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 35%;
}

.inputs-mid-content-header{
    display: flex;
    flex-direction: row;
    gap: .6rem;
}

.inputs-mid-content-input{
    background-color: var(--bg2);
    width: 100%;
    border-radius: 7px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: .2rem;
    padding: 20px
}
  
#input-name{
    font-weight: 600;
    font-size: var(--fontsize-medium);
    width: fit-content;
    color: var(--textMain);
}

#nextEventFooter{
    display:flex;
    align-items:center;
    height:fit-content;
    margin-left:1px;
}

.oder{
    font-weight: 600;
    color: var(--textSecondary);
    font-size: var(--fontsize-medium);
}

.input-subname{
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
    margin-bottom: 5px;
}

.inputs-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

#nextEventDatum {
    color: var(--blue);
    font-size: var(--fontsize-regular);
    font-weight: 600;
    font-style: bold;
    z-index: 1;
}

#eventDetailsTextDetails {
    font-size: var(--fontsize-regular);
    color: var(--textSecondary);
}

#aufrufeIcon {
    color: var(--textSecondary);
    z-index: 1;
    font-size: var(--fontsize-medium);
}

#geteiltIcon {
    font-size: var(--fontsize-medium);
    color: var(--textSecondary);
    z-index: 1;
}

#gespeichertIcon {
    font-size: var(--fontsize-medium);
    color: var(--textSecondary);
    z-index: 1;
}
  
#nextEventAufrufe {
    color: var(--textSecondary);
    z-index: 1;
    font-size: var(--fontsize-regular);
}

.forFutureEvents{
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin-left: auto;
    margin-right: auto;
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    justify-content: center;
    align-items: center;
}

.choose-event-body{
    height: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 10px;
}

.selectAllEvents{
    user-select: none;
    height: 2.5rem;
    border-radius: 7px;
    font-size: var(--fontsize-medium);
    background-color: var(--bg7);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.ButtonText{
    font-size: var(--fontsize-small);
}

#EventBanner{
    border-radius: 5px 5px 0px 0px;
    height:20.278330019880716vh;
    width:44.791666666666664vw;
    top: 0px;
    object-fit: cover;
}

.fakeInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 35%;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: var(--bg3);
    color: var(--textMain);
    padding: 5px 15px;
}

.blackSheepInput {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: none;
    background-color: var(--bg3);
    color: var(--textMain);
    outline: none;
    padding: 0px;
}

.usageButton {
    padding: 15px;
    background-color: var(--bg5);
    border-radius: 5px;
    color: var(--textMain);
    font-size: var(--fontsize-regular);
    justify-content: center;
    display: flex;
    width: 35%;
    cursor: pointer;
}

input[type="radio"]:checked+.usageButton {
    background-color: var(--bgBlue);
}

.begrenztAdditional{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
}