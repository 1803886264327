

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

body{
  user-select: none;
  overflow-x: hidden;
  background-image: none;
  background-color: var(--darkgrey);
}

select,   textarea,  input, input[type="text"],   input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] { font-size: 16px !important; }

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");


:root{
  font-family: Montserrat;
  font-size: var(--fontsize-regular);
  font-weight: 400;
}

textarea{outline: none}


/*colors*/
:root {
  --white: #ffffff;
  --black: #000000;
  --dark: #090909;
  --darkgrey: #222222;
  --grey: #4b4b4b;
  --lightgrey: #9b9b9b;
  --light: #e6e6e6;
  --blue: #0d6efd;
  --red: #f20000;
  --green: #4ab022;
  --orange: #be810e;
  --purple: #8b44fd;
  --yellow: #fdfd44;


  --lightMode2: #f4f4f4;
  --lightMode3: #dddddd;
  --lightMode4: #dfdfdf;
  --lightMode5: #959595;
  --lightMode6: #717171;
  --lightMode7: #373737;
}

.light-theme{
  --buttonBgMain: var(--blue);
  --buttonCoMain: var(--white);
  --textMain: var(--black);
  --textSecondary: var(--lightgrey);
  --textThird: var(--white);
  --textFourth: var(--light);
  --bg1: var(--white);
  --bg2: var(--lightMode2);
  --bg3: var(--lightMode3);
  --bg4: var(--lightMode4);
  --bg5: var(--lightMode5);
  --bg6: var(--lightMode6);
  --bg7: var(--lightMode7);
  --bgBlue: var(--blue);
  --bgRed: var(--red);
  --bgOrange: var(--orange);
  --bgGreen: var(--green);
  --bgPurple: var(--purple);
}

.dark-theme {
  --buttonBgMain: var(--blue);
  --buttonCoMain: var(--white);
  --textMain: var(--white);
  --textSecondary: var(--lightgrey);
  --textThird: var(--black);
  --textFourth: var(--dark);
  --bg1: var(--black);
  --bg2: var(--dark);
  --bg3: var(--darkgrey);
  --bg4: var(--grey);
  --bg5: var(--lightgrey);
  --bg6: var(--light);
  --bg7: var(--white);
  --bgBlue: var(--blue);
  --bgRed: var(--red);
  --bgOrange: var(--orange);
  --bgGreen: var(--green);
  --bgPurple: var(--purple);
}


/*fontsizes*/
:root {
  --fontsize-small: 12px; /* 12px */
  --fontsize-regular: 14px; /* 14px */
  --fontsize-medium: 16px; /* 16px */
  --fontsize-large: 18px; /* 18px */
  --fontsize-big: 20px; /* 20px */
  --fontsize-huge: 22px; /* 22px */
  --fontsize-massive: 24px; /* 24px */
  --fontsize-gigantic: 26px; /* 26px */
  --fontsize-enormous: 30px; /* 30px */
  --fontsize-humongous: 36px; /* 36px */
  --fontsize-yourmum: 40px; /* 40px */
}


@media only screen and (max-height: 925px) {
  :root {
    --fontsize-small: 10px;
    --fontsize-regular: 12px; 
    --fontsize-medium: 14px; 
    --fontsize-large: 16px; 
    --fontsize-big: 18px; 
    --fontsize-huge: 20px; 
    --fontsize-massive: 22px; 
    --fontsize-gigantic: 24px; 
    --fontsize-enormous: 26px; 
    --fontsize-humongous: 34px; 
    --fontsize-yourmum: 38px; 
  }
}


@media only screen and (max-height: 880px) {
  :root {
    --fontsize-small: 9px;
    --fontsize-regular: 11px;
    --fontsize-medium: 13px;
    --fontsize-large: 15px;
    --fontsize-big: 17px;
    --fontsize-huge: 19px;
    --fontsize-massive: 21px;
    --fontsize-gigantic: 23px;
    --fontsize-enormous: 25px;
    --fontsize-humongous: 33px;
    --fontsize-yourmum: 37px;
  }
}

/* 3 Version smaller
@media only screen and (max-height: 850px) {
  :root {
    --fontsize-small: 9px;
    --fontsize-regular: 11px;
    --fontsize-medium: 13px;
    --fontsize-large: 15px;
    --fontsize-big: 17px;
    --fontsize-huge: 19px;
    --fontsize-massive: 20px;
    --fontsize-gigantic: 21px;
    --fontsize-enormous: 22px;
    --fontsize-humongous: 33px;
    --fontsize-yourmum: 37px
  }
}

4 Version smaller
@media only screen and (max-height: 840px) {
  :root {
    --fontsize-small: 9px;
    --fontsize-regular: 10px;
    --fontsize-medium: 12px;
    --fontsize-large: 14px;
    --fontsize-big: 16px;
    --fontsize-huge: 18px;
    --fontsize-massive: 19px;
    --fontsize-gigantic: 20px;
    --fontsize-enormous: 21px;
    --fontsize-humongous: 32px;
    --fontsize-yourmum: 36px
  }
}

font size for large screens and desktops
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  :root {
    --fontsize-small: 14px;
    --fontsize-regular: 16px;
    --fontsize-medium: 18px;
    --fontsize-large: 20px;
    --fontsize-big: 22px;
    --fontsize-huge: 24px;
    --fontsize-massive: 26px;
    --fontsize-gigantic: 28px;
    --fontsize-enormous: 32px;
    --fontsize-humongous: 38px;
    --fontsize-yourmum: 42px
  }
}

*/

body {
  margin: 0;
}

textarea {
  font-family: Montserrat;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

input {
  outline: none;
  border: none;
  padding: unset;
}

select{
  outline: none;
  border: none;
}

button{
  outline: unset;
  border: unset;
  background-color: unset;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  padding: unset;
}

.underlined{
  text-decoration: underline;
}


/* Modal Styling */

.modalContentNormal {
  background-color: var(--bg3);
  width: 90vw;
  height: 85vh;
  border-radius: 5px;
  position: relative;
  transform: translate(-50%,-50%);
  inset: 50% 40px 40px 50%;
}

.modalContentSmall {
  background-color: var(--bg3);
  width: 85vw;
  height: 80vh;
  border-radius: 5px;
  position: relative;
  transform: translate(-50%,-50%);
  inset: 50% 40px 40px 50%;
}

@media (max-width: 768px) {
  .modalContentNormal {
    width: 95vw;
    height: 90vh;
    margin-top: 10px;
    border-radius: 5px;
  }

  .modalContentSmall {
    margin-top: 30px;
  }
}

.modalOverlayNormal {
  z-index: 4;
  background-color: rgba(53, 53, 53, 0.9);
  position: fixed;
  inset: 0px;
}


/* List of general classes */
/* Frequent easy classes start */
@media screen and (max-width: 768px) {
  .el-hidden-small{
    display: none !important;
    visibility: hidden !important;
  }

  .el-visible-small{
    display: unset;
    visibility: visible !important;
  }
}

@media screen and (min-width: 768px) {
  .el-hidden-small{
    display: unset;
    visibility: visible !important;
  }

  .el-visible-small{
    display: none !important;
    visibility: hidden !important;
  }
}
@media screen and (max-width: 1355px) {
  .el-hidden-medium{
    display: none !important;
    visibility: hidden !important;
  }

  .el-visible-medium{
    display: unset;
    visibility: visible !important;
  }
  .el-visible-large{
    display: none !important;
    visibility: hidden !important;
  }

  .el-hidden-large{
    display: unset !important;
    visibility: visible !important;
  }
}

@media screen and (min-width: 1356px) {
  .el-hidden-medium{
    display: unset;
    visibility: visible !important;
  }

  .el-visible-medium{
    display: none !important;
    visibility: hidden !important;
  }
  .el-visible-large{
    &:is([data-table]) {
      display: table-cell !important;
    }
    &:not([data-table]) {
      display: unset !important;
    }
    visibility: visible !important;
  }

  .el-hidden-large{
    display: none !important;
    visibility: hidden !important;
  } 
}

.p-15{padding: 15px;}

.p-25{padding: 25px;}

.p-20-30{padding: 20px 30px;}

.p-20{padding: 20px}

.ps-10{padding-left: 10px;}

.border-full{
  border-radius: 500px;
}

.border-{
 &-r-{
    &0{
      border-right: 0px !important;
    }
 }
}

.object-cover{
  object-fit: cover;
}

.p{
  &-10p{padding: 10px;}
  &-15p{padding: 15px;}
  &-20p{padding: 20px;}
  &-25p{padding: 25px;}

}

.m{
  &-10p{margin: 10px;}
  &-15p{margin: 15px;}
  &-20p{margin: 20px;}
  &-25p{margin: 25px;}
  &t-{
    &10p{margin-top: 10px;}
    &15p{margin-top: 15px;}
    &20p{margin-top: 20px;}
    &25p{margin-top: 25px;}
  }
  &b-{
    &10p{margin-bottom: 10px;}
    &15p{margin-bottom: 15px;}
    &20p{margin-bottom: 20px;}
    &25p{margin-bottom: 25px;}
  }
  &l-{
    &10p{margin-left: 10px;}
    &15p{margin-left: 15px;}
    &20p{margin-left: 20px;}
    &25p{margin-left: 25px;}
  }
  &r-{
    &10p{margin-right: 10px;}
    &15p{margin-right: 15px;}
    &20p{margin-right: 20px;}
    &25p{margin-right: 25px;}
  } 
}

// Dynamic Class Generators
@mixin size-dynamic($prefix, $property) {
  @each $size in (10, 20, 30, 40, 50, 75, 100) {
    .#{$prefix}-#{$size}p {
      #{$property}: #{$size}px !important;
    }
    .#{$prefix}-#{$size}vh {
      #{$property}: #{$size}vh !important;
    }
    .#{$prefix}-#{$size}pct {
      #{$property}: #{"#{$size}%"} !important;
    }
  }

  @for $i from 1 through 500 {
    .#{$prefix}-#{$i}p {
      #{$property}: #{$i}px !important;
    }
    .#{$prefix}-#{$i}vh {
      #{$property}: #{$i}vh !important;
    }
    .#{$prefix}-#{$i}pct {
      #{$property}: #{"#{$i}%"} !important;
    }
  }
}

@mixin size-dynamic-range($prefix, $property) {
  @each $size in (10, 20, 30, 40, 50, 75, 100) {
    .#{$prefix}-#{$size}p {
      #{$property}: #{$size}px !important;
    }
    .#{$prefix}-#{$size}vh {
      #{$property}: #{$size}vh !important;
    }
    .#{$prefix}-#{$size}pct {
      #{$property}: #{"#{$size}%"} !important;
    }
  }

  @for $i from 1 through 500 {
    .#{$prefix}-#{$i}p {
      #{$property}: #{$i}px !important;
    }
    .#{$prefix}-#{$i}vh {
      #{$property}: #{$i}vh !important;
    }
    .#{$prefix}-#{$i}pct {
      #{$property}: #{"#{$i}%"} !important;
    }
  }
}
// Include the mixins with different prefixes and properties
@include size-dynamic("w", "width");
@include size-dynamic("h", "height");
@include size-dynamic("m", "margin");
@include size-dynamic("p", "padding");

@include size-dynamic-range("min-w", "min-width");
@include size-dynamic-range("max-w", "max-width");
@include size-dynamic-range("min-h", "min-height");
@include size-dynamic-range("max-h", "max-height");



.border-radius-7{
  border-radius: 7px;
}

.t-white{
  color: var(--textMain);
}

.t-secondary{
  color: var(--textSecondary);
}

.t-bg4{
  color: var(--bg4);
}

.no-overflow{
  overflow: hidden;
}

.col-flex{
  display: flex;
  flex-direction: column;
}

.gap-1{
  gap: 5px;
}

.gap-2{
  gap: 10px;
}

.gap-3{
  gap: 15px;
}

.gap-4{
  gap: 20px;
}

.flex-gap-1rem{
  gap: 1rem;
}

.flex-gap-2rem{
  gap: 2rem;
}

.flex-gap-3rem{
  gap: 3rem !important;
}

.flex-gap-10px{
  gap: 10px;
}

.flex-gap-5px{
  gap: 5px;
}

.flex-gap-10{
  display: flex;
  gap: 10px;
  align-items: center;
}

.row-flex{
  display: flex;
  flex-direction: row;
}

.flex-wrap{
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lightWhite{
  color: var(--textSecondary);
}

.justify_start{
  justify-content: flex-start !important;
}

.justify_center{
  justify-content: center;
}

.justify_space_between{
  justify-content: space-between;
}

.justify_space_around{
  justify-content: space-around;
}

.white{
  color: var(--textMain);
}

.small{ font-size: var(--fontsize-small) }
.regular{ font-size: var(--fontsize-regular) }
.medium{ font-size: var(--fontsize-medium) }
.large{ font-size: var(--fontsize-large) }
.big{ font-size: var(--fontsize-big) }
.huge{ font-size: var(--fontsize-huge) }
.massive{ font-size: var(--fontsize-massive) }
.gigantic{ font-size: var(--fontsize-gigantic) }
.enormous{ font-size: var(--fontsize-enormous) }
.humongous{ font-size: var(--fontsize-humongous) }
.yourmum{ font-size: var(--fontsize-yourmum) }


.d-{
  &flex{
    display: flex !important;
  }

  &block{
    display: block !important;  
  }

  &inline{
    display: inline !important;
  }

  &inline-block{
    display: inline-block !important;
  }

  &none{
    display: none !important;
  }
}

.v-{
  &hide{
    visibility: hidden !important;
  }

  &show{
   visibility: visible !important;
  }

}
  
.text-color__main{
    color: var(--textMain) !important;
}

.text-color__black{
  color: var(--textThird) !important;
} 

.text-color__grey{
  color: var(--grey) !important;
}
.text-color__lightgrey{
  color: var(--lightgrey) !important;
}

.text-color__blue{
  color: var(--blue) !important;
}

.text-color__red{
  color: var(--red) !important;
}

.bg-{
  &green{
    background-color: var(--green);
  }

  &white{
    background-color: var(--white);
  }

  &black{
    background-color: var(--black);
  }

  &red{
    background-color: var(--red);
  }

  &blue{
    background-color: var(--blue);
  }
}

.text{
  &-center{
    text-align: center;
  }
}

.align_center{
    align-items: center;
}

.align_self_center{
  align-self: center;
}

.align_start{
  align-items: flex-start !important;
}

.header1{
  font-size: var(--fontsize-big);
  color: var(--textThird);
}

.header2{
  font-size: var(--fontsize-medium);
  color: var(--textThird);
}

.header3{
  font-size: var(--fontsize-small);
  color: var(--textThird);
}

.Header3{
  color: var(--textMain);
  font-size: var(--fontsize-medium);
}

.Montserrat_600{
  font-weight: 600;
}

.font-semibold{
  font-weight: 600;
}

.fontSize-12px{
  font-size: var(--fontsize-small) !important;
}

.fontSize-14px{
   font-size: var(--fontsize-regular) !important;
}

.fontSize-16px{
  font-size: var(--fontsize-medium) !important;
}

.fontSize-18px{
  font-size: var(--fontsize-large) !important;
}

.fontSize-20px{
  font-size: var(--fontsize-big) !important;
}

.fontSize-22px{
  font-size: var(--fontsize-huge) !important;
}

.fontSize-24px{
  font-size: var(--fontsize-massive) !important;
}

.fontSize-30px{
  font-size: var(--fontsize-enormous) !important;
}

.fontSize-36px{
  font-size: var(--fontsize-humongous);
}



.cursor{
  &-text{
    cursor: text;
  }
}

.cursor{
  &-text{
    cursor: text;
  }
}

  .opacity-05{opacity: .5 !important }


  /* Text Color classes */
  .TextGrey{color:var(--grey) !important}

  .TextDarkgrey{color:var(--darkgrey) !important}

  .TextWhite{color:var(--textMain) !important}

  .textCoMain{color: var(--buttonCoMain) !important}


  /* Background Color classes */
  .bg-grey{background-color: var(--bg4) !important}
  .bg-black{background-color: var(--black);}
  .bg-darkgrey{background-color: var(--bg3) !important}
  .bg-white{background-color: var(--bg7) !important}
  .bg-blue{background-color: var(--bgBlue) !important}

  .z-index-999{
    z-index: 999 !important;
  }



.ComingSoon{
  font-size: var(--fontsize-large);
  color: var(--textSecondary);
  position: relative;
  top: 45%;
  left: 50%;
  transform: translate(-50%,0%);
  width: fit-content;

}

.PageHeaderBox {
  min-height: 100px;
  background-color: var(--bg2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 25px;
  margin-bottom: 10px;
  gap: 20px;
  justify-content: space-between;
}

.PageHeaderBoxTitle{
  color: var(--textMain);
  font-weight: 600;
  font-size: var(--fontsize-humongous);
}

.PageHeaderBoxSubtitle {
  color: var(--textSecondary);
  font-size: var(--fontsize-medium);
}

.content {
  width: calc(100vw - 266px);
  background-color: var(--bg3);
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px 30px;
  height: calc(100vh - 100px);
  scrollbar-width: none;
}

#breadcrumb-title-text {
  color: var(--textSecondary);
  font-size: var(--fontsize-regular);
  font-weight: 600;
  width: fit-content;
}

.breadcrumb {
  color: var(--textSecondary);
  cursor: pointer;
  text-decoration: none;
}

.row{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.Item {
  width: 205px;
  font-size: var(--fontsize-regular);
  height: 55px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: var(--textMain);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*start copy-paste from https://stackoverflow.com/a/29512692*/
table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
table thead {
  /* head takes the height it requires, 
  and it's not scaled when table is resized */
  flex: 0 0 auto;
  width: 100%;
}
table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
}
table tbody tr {
  width: 100%;
}
table thead, table tbody tr {
  display: table;
  table-layout: fixed;
}
/*end copy-paste from https://stackoverflow.com/a/29512692*/

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 7px;
}

td{
  text-align: center;
  border-right: 1px solid var(--lightgrey);
}

th{
  border-bottom: 1px solid;
}

table, th,td {
  border-radius: 7px;
  justify-content: center;
  align-items: center;
}

/*for the select arrow*/
select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9' stroke='white'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

#newEventButton {
    all: unset;
    width: 200px;
    font-size: var(--fontsize-regular);
    background-color: var(--bgBlue);
    color: var(--buttonCoMain);
    height: 55px;
    border-radius: 5px;
    gap: 10px;  
    display: flex;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    position: unset;
    cursor: pointer;
}

@media (max-width: 768px) {
  #newEventButton {
    width: 0px;
    height: 0px;
    padding: 25px;
  }

  .buttonText{
    display: none;
  }
}


#newEventButton:disabled {
  opacity: 40%;
}

.VorschauContainer {
  border-radius: 7px;
  background-color: var(--bg2);
  width: 40vw;
  position: sticky;
  padding: 20px;
  top: 0;
}

.ButtonsBox{
  display: flex;
  flex-direction: row;
  width: 32vw;
  gap: 10px;
  justify-content: flex-end;
}

.inputBorder-primary{
  border: 1px solid var(--blue) !important;
}


.p-30-40{
  padding:30px 40px;
}

.bg-img-100{
  background-repeat: space;
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100vh - 60px);
  width: calc(100vw - 60px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .bg-img-100{
    height: calc(100vh - 40px);
    width: calc(100vw - 40px);
    padding: 20px;
  }
}

.bg-img-1{
  background-image: url("../src/graphics/RegestrierungBg1.jpg");
}

.bg-img-2{
  background-image: url("../src/graphics/LoginBg1.jpg");
}


.position-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.w-{
  &full{
    width: 100% !important;
  }
  &fit{
    width: fit-content !important;
  }
  &auto{
    width: auto !important;
  }
  &max{
    width: max-content !important;
  }
  &min{
    width: min-content !important;
  } 
}

.w-10{
  width: 10% !important;
}

.w-25{
  width: 25% !important;
}

.w-40{
  width: 40% !important;
}

.w-50{
  width: 50% !important;
}

.w-75{
  width: 75% !important;
}

.w-90{
  width: 90% !important;
}


.w-100{
  width: 100% !important;
}

.h-{
  &full{
    height: 100% !important
  }
  &fit{
    height: fit-content !important
  }
  &auto{
    height: auto !important
  }
  &max{
    height: max-content !important
  }
  &min{
    height: min-content !important
  }
}


.h-100{
  height: 100% !important
}

.h-50{
  height: 50% !important
}

.h-40{
  height: 40% !important
}

.h-30{
  height: 30% !important
}

.h-25{  
  height: 25% !important
}

.h-10{
  height: 10% !important
}

.h-80{
  height: 80% !important
}

.min-h-30{
  min-height: 30% !important
}

.Button{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: fit-content;
  cursor: pointer;
  padding: 15px;
  text-align: center;
  width: 8vw;
  gap: 10px;
  height: 20px;
  color: var(--buttonCoMain);
}

.BlueBtn{
  background-color: var(--buttonBgMain);
  color: var(--buttonCoMain);
}

.GreyBtn{
  background-color: var(--bg4);
  color: var(--textMain);
}


.not-all-inputs-done{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: var(--buttonCoMain);
  font-size: var(--fontsize-medium);
  flex-direction: row;
  gap: 15px;
  padding: 10px 15px;
  cursor: pointer;
}

#warningInput{
  background-color: var(--bgRed);
}

#promoInput{
  background-color: var(--bgGreen);
}

#disclaimerInput{
  background-color: var(--bgOrange);
}

.pointer{
  cursor: pointer;
}

.not-allowed{
  cursor: not-allowed !important;
}
  
.VorschauContainerBody{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

#noUpcoming{
  color: var(--textMain);
  font-size: var(--fontsize-big);
  z-index: 1; 
}

.event-venue{
  color: var(--textSecondary);
  font-size: var(--fontsize-regular);
  display: flex;
  gap: .5rem;
  align-items: center;
}

.LiveBtn {
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--green);
  color: var(--textMain);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
}



/* Frequent easy classes End */



.stats-counter {
  color: var(--textMain);
  font-size: var(--fontsize-yourmum);
  font-weight: bold;
}

#details{
  color: var(--textSecondary);
  font-size: var(--fontsize-large);
  max-height: 700px;
}

.box-title-flex{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.box-title-first-ele{
  display: flex;
  justify-content: flex-start;
}

.box-title-second-ele{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.uebersichtTitelDetails {
  color: var(--textMain);
  font-size: var(--fontsize-regular);
}

.dashboardBoxTitle {
  color: var(--textMain);
  font-size: var(--fontsize-large);
  font-weight: 600;
}

.chart-box{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ErstellenInputs {
  border-radius: 7px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modalSubtitle{
  font-size: var(--fontsize-large);
  color: var(--textSecondary);
}



/* Input Box Style */
.InputBox {
  background-color: var(--bg2);
  border-radius: 7px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.InputBoxHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.InputBoxHeaderLeft{
  display: flex;
  align-items: center;
  gap: 10px;
}

.InputBoxTitle {
  display: flex;
  flex-direction: column;
}

.InputCircle {
  min-width: 40px;
  width: 40px;
  height: 40px;
  background-color: var(--bg5);
  border-radius: 50%;
  color: var(--buttonCoMain);
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleSignup{
  font-size: var(--fontsize-yourmum);
  font-weight: 600;
  text-align: center;
  color: var(--textMain);
}

.InputName {
  color: var(--textMain);
  font-size: var(--fontsize-large);
  position: relative;
}

.InputDetails {
  color: var(--textSecondary);
  font-size: var(--fontsize-small);
}

/* Input Box End */


/* Side Bar Classes start */
.SideBarErstellen {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: fit-content;
  color: var(--textMain);
  border-right: 2px solid var(--buttonCoMain);
  position: sticky;
  top: 0;
  flex-shrink: 0;
}

.SideBarErstellen > .itemSideBar {
  margin: 6px;
}

.itemSideBar {
  margin: 6px;
  height: 30px;
  display: flex;
  align-items: center;
}

.optionalSidebar{
  color: var(--textMain);
  margin-top: 45px;
  margin-bottom: 15px;
}

.erforderlichSidebar{
  color: var(--textMain);
  margin-bottom: 15px;
}

.circleSideBar{
  width: 30px;
  height: 30px;
  background-color: var(--bg5);
  border-radius: 50%;
  color: var(--buttonCoMain);
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconBeschreibung {
  color: var(--textMain);
  margin-left: 10px;
  font-size: var(--fontsize-small);
  font-weight: 600;
}
/* Side Bar Classes end */

.UploadBtnErstellen{
  background-color: var(--bg7);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#logoupload{
  color: var(--textThird);
  font-size: var(--fontsize-regular);
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.imgInp {
  display: none;
}

#output_image{
  visibility: hidden;
}

.row-input{
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.col-input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: calc(50% - 0.5rem);
}

.input-title{
  font-size: var(--fontsize-regular);
  color: var(--textSecondary);
}

.output_image_style{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  color: var(--textMain);
  position: absolute;
  object-fit: cover;
}

.recoSize {
  color: var(--textSecondary);
  font-size: var(--fontsize-small);
  text-align: center;
}

.text-center{
  text-align: center;
}


/* slider / switch */

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.switchSmall {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22.5px;
  margin-left: auto;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchSmall input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderSmall {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: var(--buttonCoMain);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderSmall:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 4px;
  background-color: var(--buttonCoMain);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--bgBlue);
}

input:checked + .sliderSmall {
  background-color: var(--bgBlue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--blue);
}

input:focus + .sliderSmall {
  box-shadow: 0 0 1px var(--blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

input:checked + .sliderSmall:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sliderSmall.round {
  border-radius: 34px;
}

.sliderSmall.round:before {
  border-radius: 50%;
}

 /* end */

.TextInput {
  height: 30px;
  border-radius: 5px;
  border: none;
  text-indent: 7px;
  background-color: var(--bg3);
  color: var(--textMain);
  padding: 5px;
}

#ausgewaehlteGenres{
  color: var(--textMain);
  font-size: var(--fontsize-small);
  margin-top: 20px;
}

.GenreBox{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.genreButton{
  height: 30px;
  border-radius: 30px;
  padding: 0 20px;
  background-color: var(--bgBlue);
  color: var(--buttonCoMain);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

#vorschauheader {
  color: var(--textMain);
  font-size: var(--fontsize-medium);
  font-weight: 600;
}

.EventBoxColumn {
  padding: 25px;
  border-radius: 5px;
  background-color: var(--bg2);
  display: flex;
  flex-direction: column;
  gap: 20px;
} 



.DraftBoxColumn {
  padding: 25px;
  border-radius: 5px;
  background-color: var(--bg2);
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
}

.title-row{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.beschreibungtitelNotActive{
  color:var(--lightgrey);
  font-weight: 600;
  cursor: pointer;
  font-size: var(--fontsize-medium);
}

.beschreibungtitelActive{
  cursor: pointer;
  color: var(--textMain);
  font-weight: 600;
  font-size: var(--fontsize-medium);
}

.beschreibungtitel {
  color: var(--textMain);
  font-size: var(--fontsize-medium);
  font-weight: 600;
}

.side-menu {
  padding-right: 30px;
  border-right: 1px solid var(--white);
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.col-text {
  color: var(--textMain);
  font-size: var(--fontsize-medium);
  margin-left: 20px;
  margin-right: 9px;
}

.circleSmall{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg5);
}

.circleNormal{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.circleBig{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#circleSidebar{
  background-color: var(--bg5);
}

.nextEventBoxEvents{
  height: fit-content;
  width: 100%;
  background-color: var(--bg3);
  border-radius: 7px;
}

.VorschauIFrame {
  border-radius: 7px;
  border: none;
  margin-bottom: 20px;
}

#lasttext {
  color: var(--textMain);
  font-size: var(--fontsize-big);
  width: 20.833333333333332vw;
  position: unset;
  top: 11.928429423459244vh;
  left: 8.333333333333334vw;
}

.v3 {
  border: 2px solid var(--textSecondary);
  width: 100%;
  position: unset;
  left: 27.6vw;
  top: 220vh;
  border-radius: 7px;
}

::-webkit-scrollbar{
  width:8px;
}

::-webkit-scrollbar-thumb{
  background-color: var(--bg7);
  border-radius: 20px;
}

::-webkit-scrollbar-track{
  background-color: var(--bg1);
  border-radius: 20px;
  border: 4px solid var(--textThird);
}

.EventBox::-webkit-scrollbar{
  width: 8px;
}

.EventBox::-webkit-scrollbar-thumb{
  background-color: var(--bg7);
  border-radius: 20px;
}

.EventBox::-webkit-scrollbar-track{
  background-color: var(--bg1);
  border-radius: 20px;
  border:4px solid var(--textThird);
}

.TextInputSelect{
  height: 40px;
  border-radius: 7px;
  border: none;
  text-indent: 5px;
  background-color: var(--bg3);
  color: var(--textMain);
  padding: 10px;
}

.item{
  color: var(--textMain);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.zusatzRow{
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  flex-direction: row;
}

.optionalHead{
  font-size: var(--fontsize-big);
  color: var(--textMain);
  font-weight: 600;
}

.optionalSubHead{
  font-size: var(--fontsize-regular);
  color: var(--textSecondary);
}

.optionalIcon{
  margin-right: 20px;
  cursor: pointer;
}

.BildBorder{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18vh;
  max-height: 170px;
  width: 100%;
  flex-grow: 1;
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--textMain);
  z-index: 0;
  position: relative;
}

.Beschreibungsuntertitel{
  color: var(--textSecondary);
  font-size: var(--fontsize-small);
  font-weight: 400;
}

.ModalHeader{
  font-weight: 600;
  font-size: var(--fontsize-humongous);
  color: var(--textMain);
}

.cancelModal{
  cursor: pointer;
  color: var(--textMain);
  font-size: var(--fontsize-gigantic);
}

.border-r-0{
  border-right: 0px;
}

.t-main{
  color: var(--textMain);
}

#delete{
  border-style: none;
  border-radius: 500px;
  background-color: var(--white);
  color: var(--red);
  padding: 10px 15px;
  gap: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fontsize-regular);
  white-space: nowrap;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 1px 0px 5px var(--lightgrey);
}

#female{
  color: var(--red);
}

#male{
  color: var(--blue);
}

#divers{
  color: var(--purple);
}

#newCustomer{
  color: var(--green);
}

#retainerCustomer{
  color: var(--orange);
}

.tooltipStatistics {
  background-color: var(--bg7);
  position: absolute;
  top: calc(100% + 5px);
  transform: translateX(-93%);
  width: fit-content;
  min-width: 200px;
  border-radius: 5px;
  z-index: 4;
}

.tooltipContainer {
  background-color: var(--bg7);
  color: var(--textThird);
  padding: 10px;
  font-size: var(--fontsize-regular);
  border-radius: 5px;
}

.tooltipHidden {
  position: relative;
}

.InfoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--textMain);
  font-size: var(--fontsize-medium);
  cursor: pointer;
}

.bestätigen-btn{
  background-color: var(--bgBlue);
  border: none;
  flex: 50%;
  height: 50px;
  border-radius: 5px;
  color: var(--buttonCoMain);
  cursor: pointer;
}

.bestätigen-btn.disabled {
  opacity: 40%;
  cursor: not-allowed;
}

#AgbLink {
  color: var(--textMain);
  text-decoration: none;
  font-size: var(--fontsize-small);
}

.header-disclaimer {
  color: var(--textSecondary);
  font-size: var(--fontsize-small);
  margin-top: 10px;
  text-align: center;
}

.suggenstionInputBox{
  width: calc(100% - 30px);
  padding-top: 10px;
  background-color: var(--bg3);
  color: var(--textMain);
  position: absolute;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 0px 0px 5px 5px;
}

.previewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.phoneFramePreview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  object-fit: cover;
  max-height: 100%;
  object-position: top;
  pointer-events: none;
}

.iframeContainer {
  flex-grow: 1;
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 87%;
  margin-top: 15px;
  border-radius: 40px 40px 0px 0px;
}

.websiteVorschauBox{
  display: flex;
  justify-content: space-between;
}

.WebsiteVorschau {
  width: 35%;
  background-color: var(--bg2);
  border-radius: 10px;
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (max-width: 1540px) {
  .content {
    width: calc(100vw - 148px);
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: calc(100vw - 30px);
    padding: 15px;
    height: calc(100vh - 90px);
  }

  .WebsiteVorschau {
    display: none;
  }

  .VorschauContainer {
    display: none;
  }

  .PageHeaderBox {
    padding: 20px;
    flex-direction: column;
    min-height: fit-content;
  }

  .InputBox {
    padding: 15px;
  }

  .ButtonsBox{
    width: 100%;
  }

  #details{
    max-height: unset;
  }
  
  .DraftBoxColumn {
    display: none;
  }

  #beschreibungtitel {
    margin-left: 5px;
    margin-right: 5px;
  }

  .ModalHeader{
    font-size: var(--fontsize-enormous);
  }

  .modalSubtitle{
    font-size: var(--fontsize-medium);
    color: var(--textSecondary);
  }
}


