@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

#festesEnde {
    color: var(--textMain);
    font-size: var(--fontsize-medium);
}

.createTicketingBtn{
    width: 100%;
    margin: auto;
    height: 40px;
    background-color: var(--bgBlue);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
}

.flexTileEintritt{
    background-color: var(--bg3);
    border-radius: 5px;
}

.tileTextEintritt{
    margin: 15px;
    font-size: var(--fontsize-medium);
    font-weight: 600;
    color: var(--textMain);
}

.input-top-label {
    font-size: var(--fontsize-small);
    margin-top: 10px;
    margin-bottom: 5px;
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
}

.selectMinAlter {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.selectMinAlterItem {
    cursor: pointer;
    background-color: var(--bg3);
    color: var(--textMain);
    gap: 10px;
    height: 30px;
    padding: 0 20px;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectMinAlterItem.active {
    background-color: var(--blue);
    color: var(--buttonCoMain);
}

#specialInput{
    width: 100%;
}

#noDresscodeBtn {
    cursor: pointer;
    border-radius: 7px;
    background-color: var(--bg7);
    padding: 10px;
}

#freierEintrittText {
    position: relative;
    top: 45%;
    left: 63.5%;
    transform: translate(-50%, -50%);
    font-size: var(--fontsize-large);
}

.hochscrollBtn {
    border-radius: 50%;
    padding: 10px 15px;
    position: fixed;
    bottom: 2.982107355864811vh;
    right: 3.6458333333333335vw;
    background-color: var(--bgBlue);
    cursor: pointer;
    display: flex;
    z-index: 1;
    justify-content: center;
    align-items: center;
}

#hochscrollIcon {
    color: var(--textMain);
    font-size: var(--fontsize-massive);
}

#festesDatum {
    display: none;
}

#minAlterInp {
    border-radius: 80px;
    width: 40px;
    height: 30px;
    padding: 0px 15px;
    border: 1px solid var(--blue);
}


#beschreibung{
    color: var(--textMain);
    border-radius: 7px;
    background-color: var(--bg3);
    min-height: 150px;
    border: none;
    outline: none;
    padding: 10px;
}

.rowProfilBearbeiten{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
}

#Verlinkungen{
    background-color: var(--bg7);
    color: var(--textThird);
}

.socialMediaBox{
    background-color: var(--bg1);
    color: var(--textMain);
    display: flex;
    border-radius: 10px;
    padding: 10px 15px;
}

.verlinkungsIcon{
    font-size: var(--fontsize-massive);
    color: var(--textMain);
}

.leftVerlinkung{
    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bigMediaBox{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.postingDateInput{
    color: var(--textThird);
}

.verlinkungenFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.editPostBtn{
    background-color: var(--bg4);
    color: var(--textMain);
    border-radius: 5px;
    padding: 5px 10px;
}

.specialsBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.specialsRow{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#addSpecialsBtn{
    display: flex;
    border-radius: 5px;
    background-color: var(--bg3);
    color: var(--textMain);
    padding: 10px;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.event-form-wrapper {
    background-color: var(--bg3);
    height: 100vh;
    overflow-y: hidden;
}

.event-form-wrapper-success {
    background: rgb(31,196,228);
    background: linear-gradient(0deg, rgba(31,196,228,1) 0%, rgba(249,153,22,1) 100%);
    height: 100vh;
    overflow-y: hidden;
}

@media only screen and (min-width: 765px) {
    .event-form-wrapper {
      display: flex;
      justify-content: center;
    }

    .event-form-wrapper-success{
        display: flex;
        justify-content: center;
    }
}

.event-form {
    height: calc(100vh - 40px);
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 20px;
}
  
@media only screen and (min-width: 765px) {
    .event-form {
        width: 765px;
    }
}

.event-form-overview {
    display: flex;
    flex-direction: column;
    color: var(--textMain);
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

.event-form-overview .SideBarErstellen {
    position: unset;
    border-right: unset;
}

.submit-button {
    cursor: pointer;
    background-color: var(--bgBlue);
    color: var(--buttonCoMain);
    text-align: center;
    border-radius: 5px;
    border-style: none;
    font-size: var(--fontsize-large);
    padding: 15px;
    margin: 15px 0;
}
  
.submit-button:disabled {
    cursor: default;
    background-color: var(--bg5);
}

.selectReoccurance{
    width: 70%;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    background-color: var(--bg4);
    color: var(--textMain);
    margin: 0;
    padding: 0;
}

.recorrunceRow{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.preSaleRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

@media only screen and (max-width: 768px) {
    .recorrunceRow{
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .selectReoccurance{
        width: 100%;
    }

    .preSaleRow{
        flex-direction: column;
    }
}