.subscribe-modal {
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.subscribe-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--textMain);
    overflow-y: auto;
    align-items: center;
}

.subscribe-modal-title {
    display: flex;
    font-weight: 600;
    font-size: var(--fontsize-yourmum);
    margin-bottom: 5px;
}

.subscribe-modal-subtitle {
    display: flex;
    font-size: var(--fontsize-huge);
    text-align: center;
    color: var(--textSecondary);
}

.subscribe-modal-titlebox{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}

.subscribe-modal-progress {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
}

.subscribe-modal-progress-item {
    display: flex;
    font-size: var(--fontsize-large);
    color: var(--textSecondary);
    gap: 10px;
}

.subscribe-modal-progress-item.active {
    color: var(--textMain);
}

.subscribe-modal-progress-item.done {
    color: var(--green);
}

.subscribe-modal-progress-item-number {}

.subscribe-modal-progress-item-name {}

.subscribe-modal-subscriptions {
    display: flex;
    gap: 10px;
    flex-direction: row;
    margin-bottom: 15px;
}

.subscribe-modal-subscriptions-item {
    padding: 15px 20px;
    background-color: white;
    color: black;
    border-radius: 5px;
    width: 270px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subscribe-modal-subscriptions-title {
    color: black;
    font-size: var(--fontsize-gigantic);
    font-weight: 600;
}

.subscribe-modal-subscriptions-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.subscribe-modal-subscriptions-button {
    padding: 10px;
    background-color: var(--green);
    color: var(--textMain);
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.subscribe-modal-subscriptions-placeholder {
    padding: 10px;
    background-color: var(--bg5);
    color: var(--textMain);
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.subscribe-modal-success-button {
    padding: 10px;
    background-color: var(--bg7);
    color: var(--textThird);
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    justify-content: center;
    display: flex;
    width: 200px;
    text-decoration: none;
}

.subscribe-modal-price{
    display: flex;
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
}

.subscribe-modal-priceBox{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.loadingPlaceholder{
    display: flex;
    justify-content: center;
}


.subscribe-modal-subscriptions-benefit{
    display: flex;
    flex-direction: row;
    font-size: var(--fontsize-large);
    gap: 20px;
}

.subscribe-modal-subscriptions-benefitbox{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.subscribe-modal-subscriptions-allbenefits{
    display: flex;
    color: var(--textSecondary);
    gap: 5px;
    align-content: center;
    justify-content: center;
    text-decoration: underline;
    cursor: pointer;
}

.subscribe-modal-content-mini {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscribe-modal-icons{
    color: var(--textMain);
    font-size: var(--fontsize-gigantic);
    cursor: pointer;
}

.subscribe-modal-iconbox{
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}