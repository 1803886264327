@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.SideBarModal{
    width: 250px;
    height: 100%;
    background-color: var(--bg2);
    border-radius: 5px 0px 0px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
.SideBarTitle{
    margin-bottom: 20px;
    font-size: var(--fontsize-small);
    z-index: 1;
    font-weight: 600;
    color: var(--textSecondary);
    margin: 20px;
    display: flex;
}

.SideBarLogout{
    color: var(--buttonCoMain);
    background-color: var(--bgRed);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 15px;
    border-radius: 0px 0px 0px 5px;
}


.SideBarModalBox{
    position: relative;
    display: flex;
    flex-direction: column;
}


.SideBarModalItem{
    position: relative;
    color: var(--textSecondary);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 30px;
    padding: 25px 20px;
}

.SideBarModalItem.Active{
    background-color: var(--bg3);
    color: var(--textMain);
    cursor: default;
}

.SideBarModalItemText{
    font-size: var(--fontsize-small);
    font-weight: 600;
}

.Active .SideBarModalItemText{
    font-size: var(--fontsize-small);
    font-weight: 600;
}

.SideBarModalIcon{
    width: fit-content;
}

.passw-checklist-modal{
    color: var(--textMain);
    margin-top: 3px;
    align-items: center;
}

.ModalContent{
    display: flex;
    flex-direction: column;
    background-color: var(--bg3);
    position: relative;
    padding: 15px 20px;
    border-radius: 5px;
}

.ModalContentTitleBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.deleteBtn{
    padding: 10px;
    border-radius: 5px;
    background-color: var(--bgRed);
    color: var(--textMain);
    cursor: pointer;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.sendBtn{
    padding: 10px;
    border-radius: 5px;
    background-color: var(--bgBlue);
    color: var(--textMain);
    cursor: pointer;
    width: 100%;
}


.ModalContentTitle{
    color: var(--textMain);
    font-size: var(--fontsize-enormous);
    position: relative;
    font-weight:600;
    width: fit-content;
}


.v4{
    height: 1px;
    border-radius: 7px;
    background-color: var(--bg5);
    margin-top: 20px;
    flex-shrink: 0;
}

.ModalContentSectionBar{
    height: 1px;
    background-color: var(--bg4);
    margin-top: 10px;
    margin-bottom: 20px;
}

.ModalContentSectionBox{
    display: flex;
    flex-direction: column;
    gap: 50px;
    overflow-y: auto;
    padding-right: 10px;
}

.ModalContentSectionTitle{
    color: var(--textMain);
    font-weight: 600;
}

.ModalContentSectionRow{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.ModalContentTitleDetails{
    color: var(--textSecondary);
    width: fit-content;
    position: relative;
    margin-top: 25px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.newPassw{
    cursor: pointer;
    padding: 15px;
    width: 182px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    background-color: var(--buttonBgMain);
    color: var(--buttonCoMain);
    text-align: center;
    border-radius: 5px;
    font-size: var(--fontsize-regular);
    margin-top: 20px;
}

.CloseIcon{
    cursor: pointer;
    font-size: var(--fontsize-gigantic);
    color: var(--textMain);
}

.inputFlex{
    display: flex;
    flex-direction: column;
    gap: .8rem;
    width: fit-content;
}

.flexBoxBank{
    display: flex;
    flex-direction: row;
    gap: 20px; 
    flex-wrap: wrap;
}

.flexBoxButtons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: end;
}

.flexBoxBankMini{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.success-text {
    color: var(--green);
}

.warning-text {
    color: var(--orange);
}

.ticketing-activation-status{
    font-size: var(--fontsize-medium);
}

.table-invoices{
    color: var(--textMain);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.tableInvoicesBox{
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 5px 10px;

}

.invoices-table{
    text-align: start;
    width: 100%;
    &-header{
        &-name{
            text-align: start;
            width: 40%;
        }
        &-date{
            text-align: start;
            width: 20%;
        }
        &-price{
            text-align: start;
            width: 15%;
        }
    }

    &-body{
        &-name{
            text-align: start;
            width: 40%;
            border-right: 0;
        }
        &-date{
            text-align: start;
            border-right: 0;
            width: 20%;
        }
        &-price{
            text-align: start;
            border-right: 0;
            width: 15%;
        }
        &-download{
            border: 0;
        }
    }
}

@media screen and (max-width: 1355px) {
    .invoices-table{

        &-header-name{
            width: 65%;
        }
     
        &-body-name{
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &-body-download{
            width: 30%;
        }
    }
}

@media screen and (max-width: 500px) {
    .invoices-table{
        &-body-download{
            width: 45%;
        }
    }
}



.active .activate-button-payment-text{
    color: var(--textMain);
    font-size: var(--fontsize-big);
}
  
.activate-button-payment{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tableModalPayments{
    max-height: calc(60vh - 10%);
    overflow-y: scroll;
    overflow-x: hidden;
  }

.inputs-box-flex-row{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    text-indent: 5px ;
}

.password-inputs-col{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: fit-content;
}

.fixTableHead { 
    overflow-y: auto; 
    height: 100%; 
} 

.fixTableHead thead th { 
    position: sticky; 
    top: 0; 
} 

.ModalPaymentsDownloadButton{
    padding: 10px;
    background-color: var(--bg4);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
    font-size: var(--fontsize-small);
}

.TextInputModal{
    border-radius: 5px;
    height: 35px;
    width: 200px;
    text-indent: 10px;
    background-color: var(--bg4);
    color: var(--textMain);
}

.SaveButton{
    border-radius: 5px;
    background-color: var(--bgBlue);
    color: var(--textMain);
    padding: 10px 15px;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.payment-methods-modal{
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-items: center;
}

.rowItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.PaymentIcon{
    color: var(--textThird);
    font-size: var(--fontsize-big);
}

.verlinkungName{
    color: var(--textMain);
    font-size: var(--fontsize-big);
    display: flex;
}
  
.payment-method-added-date{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    min-width: 150px;
}

.activate-button-payment-text{
    cursor: pointer;
    background-color: var(--bg7);
    color: var(--textThird);
    font-size: var(--fontsize-medium);
    font-weight: 600;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}
  
.activate-button-payment-text-active{
    cursor: pointer;
    background-color: var(--green);
    color: var(--buttonCoMain);
    font-size: var(--fontsize-medium);
    font-weight: 600;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

.rowItemLeft{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.rowItemRight{
    display: flex;
    flex-direction: row;
}

.bankRowRight{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.planDetails{
    font-size: var(--fontsize-small);
    color: var(--textSecondary);
}

.cancelPlan{
    background-color: var(--bg4);
    border-radius: 5px;
    padding: 10px;
    width: 100px;
    color: var(--textSecondary);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.submitChangeBtn{
    background-color: var(--bgBlue);
    border-radius: 5px;
    padding: 10px;
    width: 120px;
    color: var(--textMain);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 17px;
}

.submitChangeBtn:disabled{
    background-color: var(--bgBlue);
    color: var(--textMain);
    opacity: 40%;
    cursor: not-allowed;
}

.cardEditCancel {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--bg4);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-weight: 600;
    color: var(--textMain);
    width: calc(100% - 20px);
}

.subscriptionBtn {
    font-size: var(--fontsize-small);
    border-radius: 5px;
    padding: 15px;
    justify-content: center;
    display: flex;
}

.subscriptionBtn.downgrade{
    background-color: var(--bg7);
    color: var(--textThird);
    cursor: pointer;
}

.subscriptionBtn.current{
    border: 1px solid var(--grey);
    color: var(--textSecondary);
    font-weight: 400;
}

.subscriptionBtn.upgrade{
    background-color: var(--bgBlue);
    color: var(--buttonCoMain);
    cursor: pointer;
}

.subscriptionPlan{
    color: var(--textMain);
    text-align: start;
    width: 25%;
    font-size: var(--fontsize-medium);
}

.pricingBox{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.pricingNumber{
    font-size: var(--fontsize-humongous);
    color: var(--textMain);
    font-weight: 800;
}

.pricingDetails{
    font-size: var(--fontsize-small);
    color: var(--textSecondary);
    font-weight: 400;
}

.planBoxTop{
    display: flex;
    flex-direction: column;
}

.aboName{
    font-weight: 600;
    margin-bottom: 5px;
}

.serviceHeader{
    color: var(--textMain);
    font-weight: 600;
    font-size: var(--fontsize-big);
    border-right: 0px;
    text-align: start;
}

.serviceDetails{
    color: var(--textSecondary);
    border-right: 0px;
    text-align: start;
}

.servicePlan{
    width: 25%;
    color: var(--textMain);
    border-right: 0px;
}

.serviceSection{
    margin-bottom: 25px;
}

.verlinkungSubname{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
}

.freeTrial{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    font-weight: 400;
    margin-top: 5px;
}

.subscriptionCanceled {
    color: var(--red);
    font-size: var(--fontsize-small);
    font-weight: 400;
    margin-top: 5px;
}

.bottomBorder{
    border-bottom: 0.5px solid var(--grey);
}

.selectSettings{
    background-color: var(--bg3);
    color: var(--textMain);
    font-size: var(--fontsize-huge);
    min-width: 230px;
}

.passwortVerifyFirst{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
}

.tableInvoicesElement{
    text-align: start;
    border-bottom: 1px solid var(--white);
}

.verifyRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bankRow{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .ModalContent {
        width: calc(80vw - 250px);
        padding: 25px 30px;
        border-radius: 0px 5px 5px 0px;
    }
}

@media (max-width: 768px) {

    .passwortVerifyFirst{
        flex-direction: column;
    }

    .verifyRow{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .bankRow{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .SideBarLogout{
        margin-top: auto;
        border-radius: 5px 5px 5px 5px;
    }

    .bankRowRight{
        flex-direction: column;
        align-items: flex-start;
    }
}

.addMemberRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.addMemberButton{
    background-color: var(--green);
    padding: 10px 20px;
    border-radius: 500px;
    color: var(--buttonCoMain);
}

.memberColumn{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.memberItem{
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: var(--bg2);
    align-items: center;
    justify-content: space-between;
}

.selectMember{
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 10px 15px;
    color: var(--textMain);
    width: 150px;
}

.selectNewMember{
    background-color: var(--bg3);
    border-radius: 5px;
    padding: 10px 15px;
    color: var(--textMain);
    width: 100%;
}

.modalContentMember {
    background-color: var(--bg2);
    width: 35vw;
    height: fit-content;
    border-radius: 5px;
    position: relative;
    transform: translate(-50%,-50%);
    inset: 50% 40px 40px 50%;
    padding: 25px 30px;
}

.addUserBtn{
    background-color: var(--bgBlue);
    color: var(--buttonCoMain);
    font-size: var(--fontsize-medium);
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
}

.rowInputAddMember{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.selectAdd{
    align-items: flex-start;
}

.btnDeleteMember{
    color: var(--textMain);
}

.btnDeleteMember:hover{
    color: var(--red);
}

.nameInputMember{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 15vw;
}

.leftSideMember{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.rightSideMember{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.selectBoxMember{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media (max-width: 768px) {
    .modalContentMember {
      width: 65vw;
    }

    .rowInputAddMember{
        flex-direction: column;
        gap: 10px;
    }

    .selectAdd{
        width: 100%;
    }

    .memberItem{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px;
        align-items: flex-start;
    }

    .leftSideMember{
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .nameInputMember{
        width: 100%;
    }

    .rightSideMember{
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        width: 100%;
    }

    .selectBoxMember{
        width: 100%;
    }

    .selectMember{
        width: 100%;
    }

    .deleteBtn{
        width: 15px;
        height: 15px;
    }
}