
.WebsiteMainbox {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
}

.websiteStats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: 60%;
    justify-content: space-between;
}

.StatisticBoxWebsite {
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 20px;
    width: 42%;
    height: 29vh;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.placeholderVorschauWebsite {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fontsize-big);
    color: var(--textSecondary);
    text-align: center;
}
