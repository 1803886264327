@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.ticketing-content{
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.ticketing-header {
    display: flex;
    flex-direction: row;
}

.back-btn-ticketing{
    border: none;
    color: var(--textMain);
    cursor: pointer;
    margin: 2.5% 60px;
}

.header-title {
    color: var(--textMain);
    font-size: var(--fontsize-humongous);
    font-weight: 600;
}

.header-title-col {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header-subtitle {
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
    margin-top: 10px;
    font-weight: 400;
}

.header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
  
.header-btns {
    display: flex;
    width: 100%;
    gap: 30px;
}

.main-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 50px;
    background-color: var(--bg3);
    overflow-y: scroll;
    scrollbar-width: none;
}

.input-boxen{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tickets-box {
    border-radius: 5px;
    padding: 15px;
    background-color: var(--bg2);
}
  
.tickets-box-header {
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

.tickets-box-title {
    font-size: var(--fontsize-large);
    color: var(--textMain);
    font-weight: 600;
}

.tickets-box-subtitle {
    color: var(--textSecondary);
    font-size: var(--fontsize-regular);
    margin-top: 5px;
}

.tickets-box-content {
    display: flex;
    flex-direction: column;
    background-color: var(--bg2);
    border-radius: 5px;
    gap: 15px;
}

.tickets-box-ticket {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: var(--bg3);
    padding: 20px;
}

.tickets-box-ticket-title {
    margin-bottom: 20px;
    color: var(--textSecondary);
    font-size: var(--fontsize-medium);
}

.spreader {
    display: flex;
    justify-content: space-between;
}

.delete-button{
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    cursor: pointer;
}

.tickets-box-inp {
    border: none;
    background-color: var(--bg2);
    border-radius: 5px;
    min-height: 25px;
    text-indent: 5px;
    color: var(--textMain);
    padding: 5px;
    flex: 50%;
}

.tickets-box-ticket-possible-sales {
    margin-left: auto;
    margin-top: 30px;
}
  
.tickets-box-ticket-possible-sales-text {
    color: var(--textSecondary);
    font-size: var(--fontsize-medium);
    font-weight: 600;
}

.service-fee {
    font-size: var(--fontsize-small);
    color: var(--textSecondary);
}

.add-ticket {
    border: none;
    background-color: var(--bgBlue);
    color: var(--buttonCoMain);
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    gap: 18px;
    border-radius: 5px;
    cursor: pointer;
    height: 35px;
}

#add-ticket-icon {
    margin-left: 10px;
}

.add-ticket-button-text {
    background-color: var(--bgBlue);
    font-size: var(--fontsize-regular);
    font-weight: 600;
    margin-right: 20px;
}

.payment-methods{
    display: flex;
    background-color: var(--bg2);
    border-radius: 5px;
    gap: 18px;
    padding: 15px;
    flex-direction: column;
}

.payment-methods-header{
    display: flex;
    flex-direction: row;
}

.row-payment-method{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.left-row-item{
    display: flex;
    flex-direction: column;
}

.payment-name{
    color: var(--textMain);
    font-weight: 600;
    font-size: var(--fontsize-medium);
}

.paymentButtonRight{
    display: flex;
    justify-content: right;
    white-space: nowrap;
}

.ticket-overview {
    max-width: 350px;
    min-width: 250px;
    height: fit-content;
    border-radius: 5px;
    background-color: var(--bg2);
    display: flex;
    padding: 15px;
    gap: 15px;
    flex-direction: column;
    position: sticky;
    top: 0;
}
  
.ticket-overview-header{
    height: 40px;
    border-radius: 5px;
    background-color: var(--bg3);
    display: flex;
    justify-content: center;
    text-indent: 15px;
    flex-direction: column;
}
  
.ticket-overview-title{
    font-weight: 600;
    color: var(--textMain);
    font-size: var(--fontsize-medium);
}
  
.ticket-overview-subheader{
    display: flex;
    flex-direction: row;
    height: fit-content;
}

.subheader-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.next-event-name-ticketOverview{
    color: var(--textMain);
    font-size: var(--fontsize-medium);
}

.tickets-overview-subtitle{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    max-width: 260px;
}

.ticketoverview-ticket-title{
    color: var(--textMain);
    font-size: var(--fontsize-large);
    font-weight: 600;
}

.ticketoverview-ticket-date{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
}

.ticketoverview-content{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: auto;
}
  
.ticketoverview-content-left{
    width: 60%;
    display: flex;
    flex-direction: column;
}
  
.ticketoverview-content-right{
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;
}

.ticketoverview-text-raw-box{
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.ticketoverview-text-raw{
    font-size: var(--fontsize-regular);
    color: var(--textSecondary);
    display: block;
}

.line{
    width: 100%;
    border: 1px solid var(--lightgrey);
    transform: translate(-90deg);
}

.ticketoverview-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ContentÜbersicht{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.variantsMapOverview{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.erklaerungstext-box{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
  
.erklaerungstext{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
    text-align: center;
}

.umsatz-box{
    display: flex;
    margin-top: 10px;
    flex-direction: row;
}

.possible-umsatz-text{
    color: var(--green);
    font-weight: 800;
    font-size: var(--fontsize-medium); 
}

#umsatz-title{
    width: 81%;
}

.possible-umsatz-subtext{
    color: var(--textSecondary);
    font-size: var(--fontsize-small);
}

.abbruch-btn {
    color: var(--textSecondary);
    border: none;
    background-color: var(--bg2);
    flex: 50%;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
}

.methodsboxsubtitle{
    font-size: var(--fontsize-small);
    text-align: center;
    color: var(--textSecondary);
    margin-bottom: 5px;
}
  
.methodsbox{
    color: var(--textMain);
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 10px;
    gap: 5px;
}

.paymentmethodquare{
    width: 50px;
    height: 30px;
    background-color: var(--buttonCoMain);
    border-radius: 5px;
    display: flex;
    color: var(--textMain);
    justify-content: center;
    align-items: center;
}

.picturestyle{
    width: 50px;
    height: 30px;
    border-radius: 5px;
    object-fit: cover;
}

#em-sales {
    font-weight: 800;
    font-style: normal;
    color: var(--textMain);
}

.activated-btn{
    padding: 0 30px;
    height: 30px;
    border-radius: 30px;
    background-color: var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--buttonCoMain);
}
  
.not-activated-btn{
    cursor: pointer;
    padding: 0 30px;
    height: 30px;
    border-radius: 30px;
    background-color: var(--bg7);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 14px;
    color: var(--textThird);
}

.eventImageMini{
    border-radius: 5px;
    width: 80px;
    height: 50px;
    margin-right: 10px;
    background-color: var(--bg4);
    object-fit: cover;
}

.additionalInformation {
    color: var(--textSecondary);
}

.specialCircle{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: var(--bg7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--textThird);
    flex-shrink: 0;
}

.cancelMobile{
    border-radius: 50px;
    height: 30px;
    width: 30px;
    background-color: var(--bg2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textMain);
    display: none;
}

.ticketoverview-mobile{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--bg2);
    padding: 15px;
    border-radius: 5px;
    display: none;
}

@media (max-width: 768px) {
    .ticketing-content{
        padding: 15px;
        gap: 20px;
    }

    .ticketing-header {
        flex-direction: column;
        gap: 25px;
    }

    .header-title {
        font-size: var(--fontsize-enormous);
    }

    .header-subtitle{
        display: none;
    }

    .header-title-col {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .abbruch-btn{
        display: none;
    }

    .cancelMobile{
        display: flex;
    }

    .ticketoverview-mobile{
        display: flex;
    }

    .tickets-box-ticket {
        padding: 15px;
    }
}