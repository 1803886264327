.saveArtistButton{
    background-color: var(--blue);
    border-radius: 5px;
    padding: 10px 30px;
    color: var(--white);
    cursor: pointer;
    text-align: center;
    font-weight: 600;
}

.artistEditContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    height: 100%;
}

.artisteditheader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.artistEditSubheader{
    margin-top: 5px;
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
}

.icon-artist-detail{
    border-radius: 500px;
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    object-fit: cover;
}