.change-content{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background-color: var(--bg3);
    border-radius: 5px;
    max-height: 600px;
    align-items: center;
    justify-content: space-between;
    width: 400px;
}

.change-modal {
    width: 30vw;
    height: 50vh;
    border-radius: 5px;
    position: relative;
    transform: translate(-50%,-50%);
    inset: 50% 40px 40px 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    color: var(--textMain);
}

@media screen and (max-width: 600px) {
    .change-content{
        width: 300px;
    }
}



.link-text{
    border-radius: 500px;
    background-color: var(--blue);
    padding: 10px 25px;
    margin-top: 5px;
    width: fit-content;
    cursor: pointer;
}

.changeTitle{
    font-size: var(--fontsize-huge);
    font-weight: 600;
}

.changeSubtitle{
    font-size: var(--fontsize-regular);
    color: var(--textSecondary);
}

.dots{
    font-size: var(--fontsize-large);
}

.changeLog{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.change-logs-map{
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    scrollbar-width: none;
    max-height: 35vh;
}

@media (max-width: 768px) {
    .change-modal {
        width: 80vw;
    }

    .change-logs-map{
        max-height: 30vh;
    }
}