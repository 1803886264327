@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.event-ticketoverview-header{
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    background-color: var(--bg2);
    padding: 20px 25px;
    gap: 15px;
}

.RowItem{
    display: flex;
    flex-direction: row;
}

.event-ticketoverview-header-title{
    color: var(--textMain);
    font-size: var(--fontsize-humongous);
    font-weight: 600;
}

.left-RowItem{
    width:70%;
}

.row-payment{
    display: flex;
    flex-direction: row;
    gap: .4rem;
    font-size: var(--fontsize-regular);
}

.status-online-btn{
    background-color: var(--green);
    display: flex;
    justify-content: center;
    color: var(--textMain);
    align-items: center;
    border-radius: 7px;
    width: 10rem;
    height: 3rem;
}

.status-offline-btn{
    color: var(--textMain);
    background-color: var(--bgRed);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    width: 10rem;
    height: 3rem;
    margin-right: 15px;
}

.left-RowItem-second-ColItem{
    display: flex;
    flex-direction: row;
}

.ticketoverview-second{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.event-name-col{
    color: var(--textSecondary);
    font-size: var(--fontsize-large);
}

.event-date-col{
    color: var(--blue);
    font-size: var(--fontsize-regular);
    margin-top: 10px;
}

.ticketbestand-title{
    color: var(--textMain);
    font-size: var(--fontsize-medium);
    margin-bottom: 5px;
}

.progressBar-ticket-overview{
    border-radius: 7px;   
}

.ticketbestand-text{
    display: flex;
    justify-content: flex-end;
    color: var(--textMain);
    font-size: var(--fontsize-regular);
    margin-top: 5px;
}

.row2{
    display: flex;
    flex-direction: row;
}

.PDF:hover{
    background-color: var(--green);
    color: var(--textMain);
    cursor: pointer;
}

.EXCEL:hover{
    background-color: var(--green);
    color: var(--textMain);
    cursor: pointer;
}

.WORD:hover{
    background-color: var(--green);
    color: var(--textMain);
}

.tooltipPdf{
    visibility: hidden;
}

.tooltipExcel{
    visibility: hidden;
}

.tooltipWord{
    visibility: hidden;
}

.tooltip{
    color: var(--textMain);
    font-size: var(--fontsize-medium);
    margin-top: 0px;
}

.table-ticket-overview {
    overflow: hidden;
    width: 100%;
}

.table-tickets{
    color: var(--textMain);
    background-color: var(--bg2);
    border-radius: 5px;
    padding: 20px;
    overflow: hidden;
    height: 100%;
    display: flex;
}

.table-tickets-box{
    border-right: 1px solid var(--textSecondary);
    width: 15%;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.table-ticket-box{
    border-right: 1px solid var(--lightgrey);
    width: 15%;
}

.table-ticket-box-small{
    border-right: 1px solid var(--lightgrey);
    width: 10%;
}

.ticketStatus{
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exportBox {
    width: 50%;
    flex-direction: row;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ticketoverview-progress {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: normal;
    flex-direction: column;
}

.table-tickets-body{
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
}

.mobile-ticketbuyer-box{
    display: flex;
    flex-direction: column;
    background-color: var(--darkgrey);
    padding: 10px;
    border-radius: 5px;
}

.mobile-boxes-buyers{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
    gap: 10px;
    width: 100%;
}

@media (max-width: 768px) {
    .table-tickets{
        padding: 10px;
    }

    .table-ticket-overview {
        overflow-x: scroll;
        scrollbar-width: none;
    }

    .table-tickets-box{
        width: 150px;
    }

    .table-ticket-box{
        width: 100px;
    }

    .table-ticket-box-small{
        width: 100px;
    }

    .status-online-btn{
        display: none;
    }
    
    .status-offline-btn{
        display: none;
    }

    .ticketoverview-second{
        justify-content: flex-start;
    }

    .event-ticketoverview-header{
        padding: 15px;
        gap: 30px;
    }

    .ticketoverview-progress {
        width: 100%;
    }
}