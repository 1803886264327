@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");

.DraftBox{
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 59vh;
    position: relative;
    scrollbar-width: none;
    scroll-behavior: smooth;
    gap: 1.5rem;
}

@media screen and (max-width: 1000px) {
    .EventsContentContainer{
        flex-direction: column !important;
    }
    .DraftBoxColumn{
        width: unset !important;
    }
    .EventBoxColumn{
        width: unset !important;
    }
}