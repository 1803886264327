.TopBarBox {
    background-color: var(--bg1);
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.SettingsIcon{
    background-color: var(--bg4);
    color: var(--textMain);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.organizerNameTop{
    color: var(--textMain);
    font-size: var(--fontsize-large);
}
  
.IconSearch {
    height: 40px;
    width: 40px;
}

#SettingIcon{
    font-size: var(--fontsize-big);
}

#user-circle-icon{
    font-size: var(--fontsize-massive);
}
  
#userIconPic{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.TopBarRight{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media screen and (max-width: 768px) {
    .TopBarBox{
        position: sticky;
        top: 0;
        z-index: 3;
    }
}